import React from "react";
import SectionHeader from "../SectionHeader";
import LinkProduct from "../LinkProduct";
import SelectUploadButton from "../SelectUploadButton";
import Image from "../../shared/Image";

const HeaderPreview = ({ customPageData, setCustomPageData, pathName }) => {
  const showLogo = (logo_data) => {
    if (logo_data === undefined) return customPageData.logo_alt;
    else {
      if (logo_data.src === "") return customPageData.logo_alt;
      else return logo_data.src;
    }
  };
  const handleOnChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;

    // Update page data
    setCustomPageData({
      ...customPageData,
      [name]: value,
    });
  };

  return (
    <section className="section-header">
      <div className="billsby-container">
        <SectionHeader title={"Header"} />

        <div className="content-wrapper">
          <div className="preview-header">
            <div
              className="preview-header__background"
              style={{
                backgroundImage: `url(${
                  customPageData.background_image.src === ""
                    ? customPageData.background_image_alt
                    : customPageData.background_image.src
                })`,
                filter: customPageData.background_image.uploaded
                  ? ""
                  : "brightness(20%)",
              }}
            ></div>
            <div
              className="preview-header__overlay"
              style={{ backgroundColor: customPageData.color_scheme }}
            ></div>
            <div className="preview-header__content">
              <div className="preview-header__logo">
                <Image
                  className="preview-header__logo-img"
                  imgSrc={{
                    original: showLogo(customPageData.logo),
                    retina: showLogo(customPageData.logo),
                  }}
                  altText={"Preview Logo"}
                />
              </div>
              <div className="preview-header__text">
                {customPageData.heading_text}
              </div>
              <div className="preview-header__buttons">
                <div
                  className="preview-header__subscribe-btn"
                  style={{ backgroundColor: customPageData.color_scheme }}
                >
                  {customPageData.subscribe_button_text}
                </div>
                <div
                  className="preview-header__account-btn"
                  style={{ color: customPageData.color_scheme }}
                >
                  {customPageData.manage_account_text}
                </div>
              </div>
            </div>
          </div>

          <div className="fields-wrapper">
            <div className="builder-row builder-row--two-column">
              <div className="builder-column">
                <div className="builder-input-label">Logo</div>
              </div>
              <div className="builder-column">
                <SelectUploadButton name={`image_logo`} page={pathName} />
              </div>
            </div>

            <div className="builder-row builder-row--two-column">
              <div className="builder-column">
                <div className="builder-input-label">Background image</div>
              </div>
              <div className="builder-column">
                <SelectUploadButton name={`image_background`} page={pathName} />
              </div>
            </div>

            <div className="builder-row builder-row--two-column">
              <div className="builder-column">
                <div className="builder-input-label">Heading text</div>
              </div>
              <div className="builder-column">
                <input
                  className="builder-input builder-input--full"
                  type="text"
                  name="heading_text"
                  onChange={handleOnChange}
                  value={customPageData.heading_text}
                />
              </div>
            </div>

            <div className="builder-row builder-row--three-column">
              <div className="builder-column">
                <div className="builder-input-label">Subscribe button text</div>
              </div>
              <div className="builder-column">
                <input
                  className="builder-input builder-input--full"
                  type="text"
                  name="subscribe_button_text"
                  onChange={handleOnChange}
                  value={customPageData.subscribe_button_text}
                />
              </div>
              <div className="builder-column">
                <LinkProduct page={pathName} />
              </div>
            </div>

            <div className="builder-row builder-row--two-column">
              <div className="builder-column">
                <div className="builder-input-label">Manage account text</div>
              </div>
              <div className="builder-column">
                <input
                  className="builder-input builder-input--full"
                  type="text"
                  name="manage_account_text"
                  onChange={handleOnChange}
                  value={customPageData.manage_account_text}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeaderPreview;
