import React from "react";
import SectionHeader from "../SectionHeader";

const MetaData = ({ customPageData, setCustomPageData }) => {
  const handleOnChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;

    // Update page data
    setCustomPageData({
      ...customPageData,
      [name]: value,
    });
  };

  return (
    <section className="section-metadata">
      <div className="billsby-container">
        <SectionHeader title={"Metadata"} />
        <div className="content-wrapper">
          <div className="builder-row builder-row--two-column">
            <div className="builder-column">
              <div className="builder-input-label">Title</div>
            </div>
            <div className="builder-column">
              <input
                className="builder-input builder-input--full"
                type="text"
                name="metadata_title"
                onChange={handleOnChange}
                value={customPageData.metadata_title}
                placeholder="Subscribe to [Company Name]"
              />
            </div>
          </div>
          <div className="builder-row builder-row--two-column">
            <div className="builder-column">
              <div className="builder-input-label">Description</div>
            </div>
            <div className="builder-column">
              <input
                className="builder-input builder-input--full"
                type="text"
                name="metadata_description"
                onChange={handleOnChange}
                value={customPageData.metadata_description}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MetaData;
