// Dependencies
import React, { useContext } from "react";

// Context
import { Context } from "../../context/Context";

// Components
import SectionHeader from "../../components/builder/SectionHeader";

const DeletePage = () => {
  const { deletePageData } = useContext(Context);

  const handleOnClick = () => {
    deletePageData();
  };
  return (
    <main className="delete-page">
      <section className="section-delete">
        <div className="billsby-container">
          <SectionHeader title={"Delete page"} />

          <div className="content-wrapper">
            <p className="builder-text">
              When you delete your page, it can't be recovered. If you come back
              to Billsby Pages and login again, we'll set you up with a brand
              new page without any of your content or changes.
            </p>
            <button
              className="btn btn--danger builder-btn builder-btn--full"
              onClick={handleOnClick}
            >
              Delete Billsby Page and logout
            </button>
          </div>
        </div>
      </section>
    </main>
  );
};

export default DeletePage;
