class ImageUploadHelper {
  checkUploadFileType = (file) => {
    if (
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/svg+xml"
    )
      return true;
    else return false;
  };

  checkUploadMaxSize = (file) => {
    const required = 32000000; //32MB
    if (file.size <= required) return true;
    else return false;
  };

  checkUploadDimensions = async (file, image_for) => {
    const imageDimensions = (file) =>
      new Promise((resolve, reject) => {
        const img = new Image();

        // the following handler will fire after the successful loading of the image
        img.onload = () => {
          const { naturalWidth: width, naturalHeight: height } = img;
          resolve({ width, height });
        };

        // and this handler will fire if there was an error with the image (like if it's not really an image or a corrupted one)
        img.onerror = () => {
          reject("There was some problem with the image.");
        };

        img.src = URL.createObjectURL(file);
      });

    const dimensions = await imageDimensions(file);
    return dimensions;
  };
}

export default new ImageUploadHelper();
