import React, { useState, useContext, useEffect } from "react";
import ReactTooltip from "react-tooltip";

import Modal from "../shared/Modal";
import { Context } from "../../context/Context";

const PagesModal = ({ show, type, handleClose, handleSubmit, data }) => {
  const { baseURL } = useContext(Context);

  const [pageName, setPageName] = useState("");
  const [pageUrl, setPageUrl] = useState("");
  const [isURLError, setIsURLError] = useState(false);
  const [isNameMissing, setIsNameMissing] = useState(false);
  const [isURLMissing, setIsURLMissing] = useState(false);

  const handleOnClick = () => {
    if (pageName === "") {
      setIsNameMissing(true);
      setIsURLMissing(false);
    } else if (pageUrl === "") {
      setIsURLMissing(true);
      setIsNameMissing(false);
    } else {
      handleSubmit(pageName, pageUrl, type)
        .then((result) => {
          console.log(result);
        })
        .catch((err) => {
          if (err === "URL_PATH_TAKEN") {
            setIsURLError(true);
            console.log("Show tooltip error");
          } else if (err === "PAGE_NAME_MISSING") {
            setIsNameMissing(true);
          }
        });
    }
  };

  useEffect(() => {
    if (type === "rename") {
      if (data !== null) {
        setPageName(data.name);
        setPageUrl(data.path);
      }
    } else {
      setPageName("");
      setPageUrl("");
    }
    setIsURLMissing(false);
    setIsNameMissing(false);
    setIsURLError(false);
  }, [data, type]);

  return (
    <Modal
      show={show}
      title={type === "add" ? "Add a Page" : "Edit Page name"}
      handleClose={handleClose}
    >
      <div className="modal-body">
        <div className="modal-form">
          <div className="modal-form--group">
            <input
              type="text"
              className={`builder-input builder-input--full ${
                isNameMissing ? "builder-input--error" : ""
              }`}
              placeholder="Page Name"
              value={pageName}
              onChange={(e) => setPageName(e.target.value)}
              data-tip="Page name cannot be blank"
              data-for="pagename"
            />
            {isNameMissing && (
              <ReactTooltip
                id="pagename"
                className="tooltips"
                place="top"
                type="light"
                effect="solid"
                delayHide={10000}
              />
            )}
          </div>
          <div className="modal-form--group">
            <span>{baseURL + "/"}</span>

            <div>
              <input
                type="text"
                className={`builder-input ${
                  isURLError || isURLMissing ? "builder-input--error" : ""
                }`}
                placeholder="pageurl"
                value={pageUrl}
                onChange={(e) => setPageUrl(e.target.value)}
                data-tip={
                  isURLError
                    ? "You've used that URL already"
                    : isURLMissing
                    ? "URL cannot be blank"
                    : ""
                }
                data-for="pageurl"
              />

              {(isURLError || isURLMissing) && (
                <ReactTooltip
                  className="tooltips"
                  place="top"
                  type="light"
                  effect="solid"
                  delayHide={10000}
                  id="pageurl"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button className="btn btn--orange btn--full" onClick={handleOnClick}>
          {type === "add" ? "Add my new page" : "Update my page"}
        </button>
      </div>
    </Modal>
  );
};

export default PagesModal;
