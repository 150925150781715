import React from "react";
import SectionHeader from "../SectionHeader";
import BuilderEditor from "../BuilderEditor";

const Faqs = ({ customPageData, setCustomPageData }) => {
  const handleOnChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;

    if (name.includes("faq")) {
      let xHolder = name.split(".");
      let index = parseInt(xHolder[1]);
      let index_name = xHolder[0] === "faqs_title" ? "title" : "answer";
      let oldFaqs = customPageData.faqs;

      oldFaqs[index][index_name] = value;

      setCustomPageData({
        ...customPageData,
        faqs: oldFaqs,
      });
    }
  };

  const handleAddFAQ = () => {
    let oldFaqs = customPageData.faqs;
    oldFaqs.push({
      title: "",
      answer: "",
    });
    setCustomPageData({
      ...customPageData,
      faqs: oldFaqs,
    });
  };

  const handleDeleteFAQ = (index) => {
    const __faqs = [...customPageData.faqs];
    __faqs.splice(index, 1);
    setCustomPageData({
      ...customPageData,
      faqs: __faqs,
    });
  };

  const handleChangeFaqs = (value, name) => {
    let xHolder = name.split(".");
    let index = parseInt(xHolder[1]);
    let index_name = xHolder[0] === "faqs_title" ? "title" : "answer";
    let new_faqs = customPageData.faqs;

    new_faqs[index][index_name] = value;

    setCustomPageData({
      ...customPageData,
      faqs: new_faqs,
    });
  };

  return (
    <section className="section-faqs">
      <div className="billsby-container">
        <SectionHeader title={"FAQS"} />

        <div className="content-wrapper">
          {customPageData.faqs.map((faq, idx) => {
            return (
              <React.Fragment key={idx}>
                <div className="builder-row-group">
                  <div className="builder-row builder-row--two-column">
                    <div className="builder-column">
                      <div className="builder-input-label">FAQ title</div>
                    </div>
                    <div className="builder-column">
                      <div className="builder-column-group">
                        <input
                          className="builder-input builder-input--full"
                          type="text"
                          name={`faqs_title.${idx}`}
                          onChange={handleOnChange}
                          value={faq.title}
                        />
                        <button
                          className="faq-delete-btn"
                          onClick={() => handleDeleteFAQ(idx)}
                        >
                          Delete this FAQ
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="builder-row builder-row--two-column">
                    <div className="builder-column builder-column--justify-start">
                      <div className="builder-input-label">FAQ answer</div>
                    </div>
                    <div className="builder-column">
                      <BuilderEditor
                        value={faq.answer}
                        callBack={handleChangeFaqs}
                        name={`faqs_answer.${idx}`}
                      />
                    </div>
                  </div>
                </div>
              </React.Fragment>
            );
          })}

          <div className="builder-row builder-row--two-column">
            <div className="builder-column"></div>
            <div className="builder-column">
              <button
                className="btn btn--orange builder-btn builder-btn--full"
                onClick={handleAddFAQ}
              >
                Add another FAQ
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faqs;
