// Dependencies
import React, { useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

// Context
import { Context } from "../../context/Context";

// Components
import SectionHeader from "../../components/builder/SectionHeader";

// Image
import billsbotImageCart from "../../assets/images/billsbot-subscription-shopping-cart.svg";

const Processing = () => {
  const { getAndSaveSubscription } = useContext(Context);
  // const currentRoute = useLocation().pathname;
  const history = useHistory();

  const backToAccount = async () => {
    if (history.action === "PUSH") {
      console.log("pushed from somewhere");
      getAndSaveSubscription();
    } else {
      console.log("Error - Reloaded processing. Redirecting to /account");
      // history.push("/account");
    }
  };

  useEffect(() => {
    backToAccount();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <main className="page-processing">
        <Helmet>
          <title>
            Processing | Billsby Pages | Simple landing pages for your
            subscription business
          </title>
          <meta
            name="description"
            content="Manage your Billsby Pages account"
          />
        </Helmet>
        <section className="section-processing">
          <div className="billsby-container">
            <SectionHeader title={"Processing your upgrade"} />
            <div className="content-wrapper">
              <p className="builder-text">
                Give us a moment to get everything setup. This won't take more
                than a few seconds.
              </p>
              <div className="processing-image">
                <img src={billsbotImageCart} alt="processing_image" />
              </div>
            </div>
          </div>
        </section>
        {/* <section className="section-404">
          <div className="billsby-container container-404">
            <div className="img-holder-404">
              <img
                className="img-404"
                srcSet={`${img_404} 1x, ${img_404} 2x`}
                src={img_404}
              />
            </div>
            <SectionHeader title={"404: There's nothing to see here"} />
            <button className="btn btn--orange" onClick={gohome}>
              Go home
            </button>
          </div>
        </section> */}
      </main>
    </>
  );
};

export default Processing;
