import Axios from 'axios';
import api_paths from '../helpers/APIPaths';
import auth from '../helpers/Auth';

export const removeImgAPI = (payload) => {
  return new Promise((resolve, reject) => {
    Axios.post(api_paths.remove_image, payload, auth.getAuthConfig())
      .then(({ data }) => resolve(data))
      .catch((err) => reject(err));
  });
};
