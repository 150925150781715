// Dependencies
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faUnderline,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import { useHistory } from "react-router-dom";

const Table = (props) => {
  const history = useHistory();
  const billsby_url = localStorage.getItem("billsbyURL");
  const url_split = billsby_url.split(".");
  const companyname = url_split[0];
  let baseURL = process.env.REACT_APP_FE_DOMAIN;
  baseURL = baseURL.replace("entercompanyname", companyname);
  // const baseURL = `https://${companyname}.subscription.page`;
  // const baseURL = `http://${companyname}.subscription.localhost:3000`;

  const [openedTooltip, setOpenedTooltip] = useState("");

  const handleAction = (type, item) => {
    if (type === "edit") {
      localStorage.setItem("pageName", item.name);
      history.push("/account/pages/" + item.path);
    } else if (type === "rename") {
      props.onClickRename(type, item);
    } else {
      props.onClickDelete(item);
    }
  };

  const openTooltip = (path) => {
    if (path === openedTooltip) setOpenedTooltip("");
    else setOpenedTooltip(path);
  };

  const checkOpenTooltip = (path) => {
    if (path === openedTooltip) return "tooltip tooltip__active";
    else return "tooltip";
  };

  return (
    <div className="custom-table pages-table">
      <div className="tr">
        {props.tableHeaders.map((header, index) => (
          <div className="th" key={index}>
            {header}
          </div>
        ))}
      </div>
      {props.items.length === 0 ? (
        <div className="tr no-data">
          <div>No data available</div>
        </div>
      ) : (
        props.items.map((item, index) => (
          <div className="tr" key={index}>
            <div className="td">{item.name}</div>
            <div className="td">
              <a
                className="builder-link"
                href={
                  item.path === "main" ? baseURL : baseURL + "/" + item.path
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.path === "main" ? baseURL : baseURL + "/" + item.path}
              </a>
            </div>
            <div className="td">
              <div className="btn-groups">
                <button
                  className="btn btn--sm"
                  onClick={() => handleAction("edit", item)}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </button>
                {item.isEditable ? (
                  <button
                    className="btn btn--sm"
                    onClick={() => handleAction("rename", item)}
                  >
                    <FontAwesomeIcon icon={faUnderline} />
                  </button>
                ) : (
                  <div className="tooltip tooltip__hover">
                    <span className="tooltiptext">
                      You can't rename your {item.name.toLowerCase()}
                    </span>
                    <button
                      className="btn btn--sm btn--disabled "
                      disabled={true}
                    >
                      <FontAwesomeIcon icon={faUnderline} />
                    </button>
                  </div>
                )}

                {item.isDeletable ? (
                  <div className={checkOpenTooltip(item.path)}>
                    <span className="tooltiptext">
                      Are you sure?{" "}
                      <span
                        className="link link__danger"
                        onClick={() => handleAction("delete", item)}
                      >
                        Delete
                      </span>
                    </span>
                    <button
                      className="btn btn--sm btn--red"
                      onClick={() => openTooltip(item.path)}
                    >
                      <FontAwesomeIcon
                        icon={faTrash}
                        style={{ color: "#D22630" }}
                      />
                    </button>
                  </div>
                ) : (
                  <div className="tooltip tooltip__hover">
                    <span className="tooltiptext">
                      You can't delete your {item.name.toLowerCase()}
                    </span>
                    <button
                      className="btn btn--sm btn--red btn--disabled"
                      onClick={() => handleAction("delete", item.path)}
                      disabled={true}
                    >
                      <FontAwesomeIcon
                        icon={faTrash}
                        style={{ color: "#D22630" }}
                      />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default Table;
