//Dependencies
import React from "react";
import { Route } from "react-router-dom";
import { Helmet } from "react-helmet";

// Components
// import PageHeader from '../components/PageHeader';
// import Footer from '../components/Footer';

const BillsbyPageRoute = ({ component: Component, ...rest }) => {
  let is404 = true;

  let host = window.location.host;
  let parts = host.split(".");
  let subdomain = "";

  if (parts.length >= 3) {
    let temp_parts_length = parts.length;
    if (parts[parts.length - 2] === "onrender") {
      console.log("onrender site");
      temp_parts_length -= 1;
      if (temp_parts_length >= 3) {
        is404 = false;
      } else {
        is404 = true;
      }
    } else if (host === "subscription.page" || host === "pages.billsby.com") {
      is404 = true;
    } else {
      is404 = false;
    }
    subdomain = parts[0];
  } else {
    if (is404) {
      window.location.href = "https://pages.billsby.com";
    }
    console.log(host);
  }
  return (
    <React.Fragment>
      <Helmet>
        <script
          async
          defer
          src="https://scripts.simpleanalyticscdn.com/latest.js"
        ></script>
        <noscript>
          {`<img src="https://queue.simpleanalyticscdn.com/noscript.gif" alt="" />`}
        </noscript>
        <script
          src="https://checkoutlib.billsby.com/checkout.min.js"
          data-billsby-company={`${subdomain}`}
        ></script>
      </Helmet>
      <Route
        {...rest}
        render={(props) => {
          return <Component {...props} />;
        }}
      />
    </React.Fragment>
  );
};

export default BillsbyPageRoute;
