const base_url = process.env.REACT_APP_BE_URL;
// const base_url = "https://billsby-pages-be-staging.onrender.com";
// const base_url = "https://billsby-pages-backend-live.onrender.com";
// const base_url = process.env.REACT_APP_BE_URL;

const api_paths = {
  base_endpoint: base_url,

  cors_solver: base_url + '/cors',

  sign_in_user: base_url + '/api/auth/sign-in',

  get_page_content: base_url + '/api/billsby-page-content/',

  fetch_all_content: base_url + '/api/billsby-page/',
  fetch_footer_content: base_url + '/api/billsby-page/footer',
  fetch_page_data: base_url + '/api/billsby-page/page-data',
  fetch_image_all: base_url + '/api/billsby/pages/images/fetch?q=all',
  fetch_image_logo: base_url + '/api/billsby/pages/images/fetch?q=image_logo',
  fetch_image_background:
    base_url + '/api/billsby/pages/images/fetch?q=image_background',
  fetch_image_gallery:
    base_url + '/api/billsby/pages/images/fetch?q=image_gallery',
  remove_image: base_url + '/api/billsby/pages/images/remove',
  save_page_data: base_url + '/api/billsby-page/save-page-data',
  save_terms_and_conditions:
    base_url + '/api/billsby-page/save-terms-and-conditions',
  save_privacy_policy: base_url + '/api/billsby-page/save-privacy-policy',
  save_footer: base_url + '/api/billsby-page/save-footer-content',
  delete_page_data: base_url + '/api/billsby-page/delete-page-data',

  single_image_upload: base_url + '/api/billsby-page-upload/image/single',

  get_and_save_subscription_details:
    base_url + '/api/billsby-page-subscription/get-and-save',

  // Linked products
  get_product_plans: base_url + '/api/billsby/products/list',
  link_product: base_url + '/api/billsby/products/link',
  unlink_product: base_url + '/api/billsby/products/unlink',
  fetch_linked_product: base_url + '/api/billsby/products/linked',

  get_my_pages: base_url + '/api/billsby-custom-pages',
  add_my_pages: base_url + '/api/billsby-custom-pages/add',
  update_my_pages: base_url + '/api/billsby-custom-pages/update',
  delete_my_pages: base_url + '/api/billsby-custom-pages/delete',

  // Custom Pages Data

  fetch_custompages_content: base_url + '/api/billsby/custompages/',
  get_custompages_details: base_url + '/api/billsby/custompages/details/',
  update_custompages_details: base_url + '/api/billsby/custompages/details/'
};

export default api_paths;
