import React, { useEffect, useState } from "react";
import FsLightbox from "fslightbox-react";
import ClickableImage from "./ClickableImage";

const LightBox = ({ data, className }) => {
  const [toggler, setToggler] = useState(false);
  const [src, setSrc] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [canToggle, setCanToggle] = useState(true);

  useEffect(() => {
    console.log("loading image");
    setSrc("");
    setThumbnail("");

    // function getBase64FromImageUrl(url) {
    //   var img = new Image();

    //   img.setAttribute("crossOrigin", "anonymous");

    //   img.onload = function () {
    //     var canvas = document.createElement("canvas");
    //     canvas.width = this.width;
    //     canvas.height = this.height;

    //     var ctx = canvas.getContext("2d");
    //     ctx.drawImage(this, 0, 0);

    //     var dataURL = canvas.toDataURL("image/png");

    //     setSrc(dataURL);
    //   };

    //   img.src = "https://cors-anywhere.herokuapp.com/" + url;
    // }

    setTimeout(() => {
      setThumbnail(data.thumbnail);
      // if (data.src.includes("http")) getBase64FromImageUrl(data.src);
      // else setSrc(data.src);
      setSrc(data.src);
      setTimeout(() => {
        setCanToggle(true);
      }, 3000);
    }, 2000);
    //eslint-disable-next-line
  }, [data.src]);

  return (
    <>
      {thumbnail === "" ? (
        ""
      ) : (
        <ClickableImage
          className={className}
          imgSrc={{ original: thumbnail, retina: thumbnail }}
          altText={"Page Gallery"}
          onClick={() => {
            if (canToggle) setToggler(!toggler);
          }}
        />
      )}
      <FsLightbox
        toggler={toggler}
        sources={[src]}
        type="image"
        disableLocalStorage={true}
        key={src}
      />
    </>
  );
};

export default LightBox;
