// Dependencies
import React, { useContext, useState, useEffect } from 'react';
import { Markup } from 'interweave';
import { Helmet } from 'react-helmet';

// Contexts
import { Context } from '../../context/Context';

// Components
import PageHeader from '../../components/billsby-page/PageHeader';
import FaqItem from '../../components/billsby-page/FaqItem';
import PageFooter from '../../components/billsby-page/PageFooter';
import LightBox from '../../components/shared/LightBox';
import NoPathYet from '../../components/billsby-page/NoPathYet';
import API_PATHS from '../../helpers/APIPaths';
import axios from 'axios';

import { useHistory } from "react-router-dom";

const PageMain = () => {
  const {
    pageData,
    loadMainPage,
    isLoadedPageContent,
    show404Page,
    isPageContentFulllyLoaded
  } = useContext(Context);
  let host = window.location.host;
  let parts = host.split('.');
  let company_name = parts[0];
  const [activeTab, setActiveTab] = useState(0);
  const [subscribeBtnAttr, setSubscribeBtnAttr] = useState({
    plan_id: null,
    product_id: null,
    cycle_id: null,
  });
  const history = useHistory();

  const fetchData = async () => {
    loadMainPage(company_name).then((result) => console.log('success', result));
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);



  const ctaSectionStyles = {
    backgroundColor: pageData.color_scheme,
    color: pageData.color_scheme
  };

  const activateTab = (index) => {
    // console.log(`activeTab::${activeTab} | index${index}`);
    setActiveTab(activeTab === index ? -1 : index);
  };

  const runScanDom = () => {
    if (typeof window.scanDomBillsby !== 'undefined') {
      console.log('Running Billsby Scan Dom ');
      window.scanDomBillsby();
    }
  };

  const fetchLinkedProducts = () => {
    let path = history.location.pathname.split('/')[1];
    axios
      .get(API_PATHS.fetch_linked_product + `?url=${company_name}&path=${path}`)
      .then(({ data }) => {
        if (data !== null) {
          setSubscribeBtnAttr(data);
          runScanDom();
        }
      });
  };

  useEffect(() => {
    if (isPageContentFulllyLoaded) {
      console.log('Loaded page content');
      fetchLinkedProducts();
      setTimeout(() => {
        runScanDom();
      }, 1000);
    }
    // eslint-disable-next-line
  }, [isPageContentFulllyLoaded]);

  return (
    <React.Fragment>
      {isLoadedPageContent && !show404Page && (
        <div className='pages-app-container'>
          <Helmet>
            <title>{pageData.metadata_title}</title>
            <meta name='description' content={pageData.metadata_description} />
            <script
              src='https://checkoutlib.billsby.com/checkout.min.js'
              data-billsby-company={company_name}
            ></script>
          </Helmet>
          <PageHeader data={pageData} />
          <main className='page-main'>
            {pageData.sales_pitch !== '&nbsp;' && (
              <section className='secton-sales-pitch page-container'>
                <Markup content={pageData.sales_pitch} />
              </section>
            )}

            {pageData.gallery.length > 0 && (
              <section className='section-gallery page-container'>
                <div className='page-gallery'>
                  {pageData.gallery.map((image, idx) => {
                    return (
                      <div
                        className='page-gallery__img-holder'
                        key={idx}
                        // style={image.uploaded ? {} : { filter: "brightness(0%)" }}
                      >
                        <LightBox
                          data={image}
                          className={'page-gallery__img-clickable'}
                        />
                      </div>
                    );
                  })}
                </div>
              </section>
            )}

            <section className='section-cta' style={ctaSectionStyles}>
              <div className='page-container'>
                <button
                  // href="javascript:void(0)"
                  className={[`page-btn`]}
                  data-billsby-product={subscribeBtnAttr.product_id}
                  data-billsby-plan={subscribeBtnAttr.plan_id}
                  data-billsby-cycle={subscribeBtnAttr.cycle_id}
                  data-billsby-type='checkout'
                >
                  {pageData.subscribe_button_text}
                </button>
              </div>
            </section>

            {pageData.faqs.length > 0
              ? pageData.faqs[0].title !== '' && (
                  <section className='section-faq page-container'>
                    <div className='accordion' role='tablist'>
                      {pageData.faqs.map((faq, idx) => (
                        <FaqItem
                          key={idx}
                          activeTab={activeTab}
                          index={idx}
                          {...faq}
                          activateTab={() => activateTab(idx)}
                        />
                      ))}
                    </div>
                  </section>
                )
              : ''}
            {/* {pageData.faqs[0].title !== "" && pageData.faqs.length > 0 && (
              <section className="section-faq page-container">
                <div className="accordion" role="tablist">
                  {pageData.faqs.map((faq, idx) => (
                    <FaqItem
                      key={idx}
                      activeTab={activeTab}
                      index={idx}
                      {...faq}
                      activateTab={() => activateTab(idx)}
                    />
                  ))}
                </div>
              </section>
            )} */}
          </main>
          <PageFooter name={company_name} colorScheme={pageData.color_scheme} />
        </div>
      )}

      {show404Page && <NoPathYet />}
    </React.Fragment>
  );
};

export default PageMain;
