// Dependencies
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const HeaderButton = ({isHidden, text, onClick}) => {
  return (
    <div className={classNames({
      'header__button': true,
      'hide': isHidden
    })}>
      <button className="btn btn--orange" onClick={onClick}>{text}</button>
    </div>
  )
}

HeaderButton.prototypes = {
  text: PropTypes.string.isRequired,
  isHidden: PropTypes.bool,
  onClick: PropTypes.func.isRequired
}

HeaderButton.defaultProps = {
  isHidden: false,
}

export default HeaderButton