// Dependencies
import escapeHtml from "escape-html";
// import { jsx } from 'slate-hyperscript'
import { Text } from "slate";

export const convertToHtmlString = (content) => {
  let htmlString = ``;

  content.forEach((node) => {
    htmlString += serialize(node);
  });

  return htmlString;
};

export const serialize = (node) => {
  if (Text.isText(node)) {
    let text = escapeHtml(node.text);

    if (text != null || text.length > 0) {
      if (node.bold) {
        text = `<strong>${text}</strong>`;
      }

      if (node.italic) {
        text = `<em>${text}</em>`;
      }

      if (node.underline) {
        text = `<u>${text}</u>`;
      }
    }

    // console.log("TEXTTTTTTT::::::", text)
    return text;
  }

  const children = node.children.map((n) => serialize(n)).join("");

  // console.log(node)
  if (children == null || children.length <= 0) {
    return children;
  }

  switch (node.type) {
    case "block-quote":
      return `<blockquote><p>${children}</p></blockquote>`;
    case "paragraph":
      return `<p>${children}</p>`;
    case "link":
      return `<a href="${escapeHtml(node.url)}">${children}</a>`;
    case "bulleted-list":
      return `<ul>${children}</ul>`;
    case "numbered-list":
      return `<ol>${children}</ol>`;
    case "list-item":
      return `<li>${children}</li>`;
    case "heading-one":
      return `<h1>${children}</h1>`;
    case "heading-two":
      return `<h2>${children}</h2>`;
    default:
      return children;
  }
};
