// Dependencies
import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { hot } from "react-hot-loader";
import history from "./history";

// Contexts
import { ContextProvider } from "./context/Context";

// Helpers
import PublicRoute from "./helpers/PublicRoute";
import AuthRoute from "./helpers/AuthRoute";
import ProtectedRoute from "./helpers/ProtectedRoute";
import BillsbyPageRoute from "./helpers/BillsbyPageRoute";

// Billsby Pages
import PageMain from "./pages/billsby-page/PageMain";
import PageTerms from "./pages/billsby-page/PageTerms";
import PagePrivacy from "./pages/billsby-page/PagePrivacy";

// Billsby Pages Builder
import Home from "./pages/builder/Home";
import Login from "./pages/builder/Login";
// import EditPage from "./pages/builder/EditPage";
import TermsAndConditions from "./pages/builder/TermsAndConditions";
import Privacy from "./pages/builder/Privacy";
import FooterContent from "./pages/builder/FooterContent";
import PageUrl from "./pages/builder/PageUrl";
import PageVisitors from "./pages/builder/PageVisitors";
// import DeletePage from "./pages/builder/DeletePage";
import DeletePageWithoutSubscription from "./pages/builder/DeletePageWithoutSubscription";
import Help from "./pages/builder/Help";
import MyPages from "./pages/builder/MyPages";
import Processing from "./pages/shared/Processing";

import EditCustomPage from "./pages/builder/EditCustomPage";

// Shared Pages
import NoRouteMatch from "./pages/shared/404";
import FourOFour from "./pages/shared/404Only";

import "./stylesheets/main.scss";

// Toast Component
import Toast from "./components/shared/Toast";

class App extends Component {
  render() {
    return (
      <Router history={history}>
        <ContextProvider>
          <div className="App">
            <Toast />
            <Switch>
              {/* Public Routes */}
              <PublicRoute path={["/", "/home"]} component={Home} exact />

              {/* Authentication Routes */}
              <AuthRoute path="/login" component={Login} />

              {/* Admin routes */}
              <ProtectedRoute
                path={["/account", "/account/pages"]}
                component={MyPages}
                exact
              />

              <ProtectedRoute
                path="/account/footer"
                component={FooterContent}
              />
              <ProtectedRoute path="/account/domain" component={PageUrl} />
              <ProtectedRoute
                path="/account/statistics"
                component={PageVisitors}
              />
              {/* <ProtectedRoute path="/account/delete" component={DeletePage} /> */}
              <ProtectedRoute
                path="/account/delete"
                component={DeletePageWithoutSubscription}
              />
              <ProtectedRoute path="/account/help" component={Help} />
              <ProtectedRoute
                path="/account/processing"
                component={Processing}
              />

              {/* Default Pages Route */}
              {/* <ProtectedRoute
                path="/account/pages/main"
                component={EditPage}
                exact
              /> */}
              <ProtectedRoute
                path="/account/pages/terms"
                component={TermsAndConditions}
              />
              <ProtectedRoute
                path="/account/pages/privacy"
                component={Privacy}
              />

              {/* Custom Page Route */}
              <ProtectedRoute
                path="/account/pages/:custom_page"
                component={EditCustomPage}
              />

              {/* Temporary routes for Pages */}
              {/* <BillsbyPageRoute
                path={["/page", "/page/main"]}
                component={PageMain}
                exact
              /> */}
              <Route path="/404" component={FourOFour} exact />
              <BillsbyPageRoute
                path="/terms"
                component={PageTerms}
                exact={true}
              />
              <BillsbyPageRoute
                path="/privacy"
                component={PagePrivacy}
                exact={true}
              />

              <BillsbyPageRoute path="/:pathname" component={PageMain} />

              {/* No Route Match */}
              <Route component={NoRouteMatch} />
            </Switch>
          </div>
        </ContextProvider>
      </Router>
    );
  }
}

export default hot(module)(App);
