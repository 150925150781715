import { dummyCustomPageData, dummyPageData } from './DummyPageData';

class PageDataAPISync {
  syncDataToState(old_data, new_data) {
    let updated_data = {
      ...old_data,
      metadata_title: new_data.metadata_title,
      metadata_description: new_data.metadata_description,
      color_scheme: new_data.color_scheme,
      heading_text: new_data.heading_text,
      subscribe_button_text: new_data.subscribe_button_text,
      manage_account_text: new_data.manage_account_text,
      sales_pitch: new_data.sales_pitch,
      faqs: JSON.parse(new_data.faqs),
      terms: new_data.terms_and_conditions,
      privacy: new_data.privacy_policy,
      footer_content: {
        fb_url: new_data.footer_facebook,
        twitter_url: new_data.footer_twitter,
        instagram_url: new_data.footer_instagram,
        copyright_notice: new_data.footer_copyright_notice,
        email_address: new_data.footer_email_address
      },
      logo: new_data.logo === undefined ? old_data.logo : new_data.logo,
      background_image:
        new_data.background_image === undefined
          ? old_data.background_image
          : new_data.background_image,
      gallery: new_data.gallery
    };

    return updated_data;
  }

  syncPageData(old_data, new_data) {
    let updated_data = {
      ...old_data,
      metadata_title: new_data.metadata_title,
      metadata_description: new_data.metadata_description,
      color_scheme: new_data.color_scheme,
      heading_text: new_data.heading_text,
      subscribe_button_text: new_data.subscribe_button_text,
      manage_account_text: new_data.manage_account_text,
      sales_pitch: new_data.sales_pitch,
      faqs: JSON.parse(new_data.faqs),
      terms: new_data.terms_and_conditions ? new_data.terms_and_conditions : '',
      privacy: new_data.privacy_policy ? new_data.privacy_policy : '',
      footer_content: {
        fb_url: new_data.footer_facebook,
        twitter_url: new_data.footer_twitter,
        instagram_url: new_data.footer_instagram,
        copyright_notice: new_data.footer_copyright_notice,
        email_address: new_data.footer_email_address
      }
    };

    return updated_data;
  }

  syncCustomPageData(old_data, new_data) {
    let updated_data = {
      ...old_data,
      metadata_title: new_data.metadata_title,
      metadata_description: new_data.metadata_description,
      color_scheme: new_data.color_scheme,
      heading_text: new_data.heading_text,
      subscribe_button_text: new_data.subscribe_button_text,
      manage_account_text: new_data.manage_account_text,
      sales_pitch: new_data.sales_pitch,
      faqs: JSON.parse(new_data.faqs)
    };

    return updated_data;
  }

  syncImages(old_data, new_data) {
    console.log('DummyData', dummyCustomPageData.gallery_alt);
    let galleryHolder = dummyCustomPageData.gallery_alt;
    let __gallery = [];

    if (new_data.image_gallery.length !== 0)
      [...galleryHolder].forEach((gallery) => {
        const temp_gallery = { ...gallery };
        new_data.image_gallery.forEach((image_gallery) => {
          if (image_gallery.for === gallery.for) {
            temp_gallery.src = image_gallery.src;
            temp_gallery.name = image_gallery.name;
            temp_gallery.type = image_gallery.type;
            temp_gallery.uploaded = true;
          }
        });
        new_data.image_gallery_thumbnails.forEach((image_gallery) => {
          if (image_gallery.for.includes(gallery.for)) {
            temp_gallery.thumbnail = image_gallery.src;
          }
        });
        __gallery.push(temp_gallery);
      });
    else __gallery = galleryHolder;

    console.log({ __gallery });

    let __logo = new_data.image_logo
      ? { ...new_data.image_logo, uploaded: true }
      : { ...dummyPageData.logo };

    let __background = new_data.image_background
      ? new_data.image_background
      : dummyPageData.background_image;

    __background.uploaded = new_data.image_background ? true : false;

    return { __gallery, __logo, __background };
  }
}

export default new PageDataAPISync();
