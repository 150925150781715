// Dependencies
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useHistory } from "react-router-dom";

// Contexts
import { Context } from "../../context/Context";

// Components
import Image from "../shared/Image";
import API_PATHS from "../../helpers/APIPaths";

const PageHeader = ({ data }) => {
  const { isLoadedPageContent, isPageContentFulllyLoaded } = React.useContext(
    Context
  );

  const [subscribeBtnAttr, setSubscribeBtnAttr] = useState({
    plan_id: null,
    product_id: null,
    cycle_id: null,
  });

  const currentRoute = useLocation().pathname;
  const history = useHistory();

  let host = window.location.host;
  let parts = host.split(".");
  let company_name = parts[0];
  let path = history.location.pathname.split("/")[1];

  if (path === "main" || path === "terms" || path === "privacy") path = "main";

  const goHome = () => {
    if (currentRoute === "/") return;
    history.push("/");
  };

  const runScanDom = () => {
    if (typeof window.scanDomBillsby !== "undefined") {
      console.log("Scanning Dom");
      window.scanDomBillsby();
    }
  };

  const fetchLinkedProducts = () => {
    axios
      .get(API_PATHS.fetch_linked_product + `?url=${company_name}&path=${path}`)
      .then(({ data }) => {
        if (data !== null) {
          setSubscribeBtnAttr(data);
          runScanDom();
        }
      });
  };

  useEffect(() => {
    if (isPageContentFulllyLoaded) {
      console.log("Page is fully Loaded");
      fetchLinkedProducts();
    }
    //eslint-disable-next-line
  }, [isPageContentFulllyLoaded]);

  return (
    <React.Fragment>
      {isLoadedPageContent ? (
        <header className="page-header">
          {runScanDom()}
          {isPageContentFulllyLoaded ? (
            <div
              className="page-header__background"
              style={{
                backgroundImage: `url(${
                  data.background_image.src !== ""
                    ? data.background_image.src
                    : data.background_image_alt
                })`,
              }}
            ></div>
          ) : (
            <div
              className="page-header__background"
              style={{
                backgroundImage: `url("")`,
              }}
            ></div>
          )}

          <div
            className="page-header__overlay"
            style={{ backgroundColor: data.color_scheme }}
          ></div>

          <div className="page-header__content">
            {isPageContentFulllyLoaded ? (
              <div className="page-header__logo">
                <Image
                  className="page-header__logo-img"
                  imgSrc={{
                    original:
                      data.logo.src !== "" ? data.logo.src : data.logo_alt,
                    retina:
                      data.logo.src !== "" ? data.logo.src : data.logo_alt,
                  }}
                  altText={"Logo"}
                  onClick={goHome}
                />
              </div>
            ) : (
              <div className="page-header__logo">
                <Image
                  className="page-header__logo-img"
                  imgSrc={{
                    original: data.logo.src !== "" ? data.logo.src : "",
                    retina: data.logo.src !== "" ? data.logo.src : "",
                  }}
                  altText={"Logo"}
                  onClick={goHome}
                />
              </div>
            )}
            <div className="page-header__text">{data.heading_text}</div>
            <div className="page-header__buttons">
              <button
                className="page-header__subscribe-btn"
                data-billsby-type="checkout"
                data-billsby-product={subscribeBtnAttr.product_id}
                data-billsby-plan={subscribeBtnAttr.plan_id}
                data-billsby-cycle={subscribeBtnAttr.cycle_id}
                style={{ backgroundColor: `${data.color_scheme}` }}
              >
                {data.subscribe_button_text}
              </button>
              <a
                href="javascript:void(0)"
                className="page-header__account-btn"
                style={{ color: data.color_scheme }}
                data-billsby-type="account"
              >
                {data.manage_account_text}
              </a>
            </div>
          </div>
        </header>
      ) : null}
    </React.Fragment>
  );
};

// Header.propTypes = {
//   mode: PropTypes.string.isRequired
// }

// Header.defaultProps = {
//   mode: 'fixed'
// };

export default PageHeader;
