import React from "react";
import SectionHeader from "../SectionHeader";
import BuilderEditor from "../BuilderEditor";

const SalesPitch = ({ customPageData, setCustomPageData }) => {
  const handleChangeSalesPitch = (value, name) => {
    setCustomPageData({ ...customPageData, sales_pitch: value });
  };

  return (
    <section className="section-sales_pitch">
      <div className="billsby-container">
        <SectionHeader title={"Sales pitch"} />
        <div className="content-wrapper">
          <BuilderEditor
            value={customPageData.sales_pitch}
            callBack={handleChangeSalesPitch}
            name={"sales_pitch"}
            testValue={customPageData.sales_pitch}
          />
        </div>
      </div>
    </section>
  );
};

export default SalesPitch;
