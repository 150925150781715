import React from "react";
import SectionHeader from "../SectionHeader";
import BuilderColorPicker from "./BuilderColorPicker";

const ColorScheme = ({ customPageData, setCustomPageData }) => {
  const handleChangeColor = (newColor) => {
    setCustomPageData({
      ...customPageData,
      color_scheme: newColor,
    });
  };

  return (
    <section className="section-colorscheme">
      <div className="billsby-container">
        <SectionHeader
          title={"Color scheme"}
          subtitle={
            "Pick the color that you'd like us to use throughout your Billsby Page. A dark color works best."
          }
        />

        <div className="content-wrapper">
          <BuilderColorPicker
            colorScheme={customPageData.color_scheme}
            onChange={handleChangeColor}
          />
        </div>
      </div>
    </section>
  );
};

export default ColorScheme;
