import React, { useContext, useEffect } from "react";
// Components
import Image from "./shared/Image";

// Assets
import logo_white from "../assets/images/logo_white.png";
import logo_white_retina from "../assets/images/logo_white@2x.png";
import billsbot_and_scientist_lab from "../assets/images/billsbot_and_scientist_lab.svg";
import home_screenshot from "../assets/images/home_screenshot.png";
import home_screenshot_retina from "../assets/images/home_screenshot@2x.png";

import { Context } from "../context/Context";

const Landing = () => {
  const { loadPageData } = useContext(Context);

  // const getBackroundPositionStyle = () => {
  //   const windowWidth = window.innerWidth;
  //   return {
  //     backgroundPosition: `${
  //       windowWidth > 1440 ? (windowWidth - 1440) / 2 - 184 : -184
  //     }px -218px`,
  //   };
  // };

  useEffect(() => {
    console.log();
    if (localStorage.getItem("authenticated") === "true") {
      loadPageData();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <main className="home">
      {/* Banner Section */}
      <section className="section-banner">
        <div className="landing-banner billsby-container">
          <div className="landing-banner__title text--lg">
            No website? No problem. Billsby Pages creates instant landing pages
            to sell and promote your subscription business.
          </div>
          <div className="landing-banner__subtitle">
            Works perfectly with{" "}
            <a
              href="https://www.billsby.com"
              className="text-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              Billsby
            </a>{" "}
            - the most powerful, customizable and easy to integrate subscription
            billing software used by hundreds of companies worldwide to simplify
            revenue operations. {" "}
            <a
              href="https://app.billsby.com/registration"
              className="text-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sign up today
            </a>{" "} from as little as $35 a month, including $15,000 per month in transaction value.
          </div>
          <div className="landing-banner__logo">
            <div className="logo">
              <span className="logo__text--a">a</span>
              <Image
                className="logo__img"
                imgSrc={{ original: logo_white, retina: logo_white_retina }}
                altText={"Billsby Logo"}
              />
              <span className="logo__text">Labs</span>
              <span className="logo__text--project">project</span>
            </div>
          </div>
        </div>
      </section>
      {/* End Banner Section */}

      {/* Parts Section */}
      <section className="section-parts">
        <div className="billsby-container">
          <div className="parts">
            <div className="parts__img-holder">
              <Image
                className="parts__img"
                imgSrc={{
                  original: home_screenshot,
                  retina: home_screenshot_retina,
                }}
                altText={"Page Preview Sample"}
              />
            </div>
            <div className="parts__info">
              <div className="parts-label">
                <div className="parts-label__title">Eye Catching Header</div>
                <div className="parts-label__subtitle">
                  Get their attention from the off with an eye-catching header.
                  You can upload your logo, choose your brand color and pick a
                  custom picture.
                </div>
              </div>

              <div className="parts-label">
                <div className="parts-label__title">Checkout and Manage</div>
                <div className="parts-label__subtitle">
                  Billsby Checkout and Account Management is built right in - no
                  integration required - you won't even have to copy and paste a
                  single line of code.
                </div>
              </div>

              <div className="parts-label">
                <div className="parts-label__title">What's Your Pitch?</div>
                <div className="parts-label__subtitle">
                  Sell your product with a sales pitch - you choose the length
                  and use rich text formatting to make it look exactly how you'd
                  like.
                </div>
              </div>

              <div className="parts-label">
                <div className="parts-label__title">Show The Good Stuff</div>
                <div className="parts-label__subtitle">
                  A picture speaks a thousand words - so choose four of your
                  best for the gallery strip and really show off your
                  subscription.
                </div>
              </div>

              <div className="parts-label">
                <div className="parts-label__title">Overcome Objections</div>
                <div className="parts-label__subtitle">
                  Answer questions with FAQs - create as many as you'd like and
                  update them instantly.
                </div>
              </div>

              <div className="parts-label">
                <div className="parts-label__title">Check All The Boxes</div>
                <div className="parts-label__subtitle">
                  We'll host your terms and conditions and privacy policy too.
                  We've got you covered.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Parts Section */}

      {/* Billsby Labs Section */}
      <section className="section-labs">
        <div className="labs billsby-container">
          <div className="labs__text-content">
            <div className="logo">
              <Image
                className="logo__img"
                imgSrc={{ original: logo_white, retina: logo_white_retina }}
                altText={"Billsby Logo"}
              />
              <span className="logo__text">Labs</span>
            </div>

            <div className="labs__title text--lg">
              Try things. Make things. Break things.
            </div>

            <div className="labs__subtitle">
              Pages is a Billsby Labs project. It's free for all Billsby
              customers, but it's not part of our core product. It might not be
              as stable as our core product, and we can't guarantee it's here
              for the long haul (but we'll give you at least 90 days notice if
              we ever shut it down).
            </div>

            <div className="labs__subtitle">
              Our customers told us that they'd like to be able to use Billsby
              even if they don't have a website. Pages is our experiment into
              how that might work. It might stay this way. It might become a
              core part of our offering. Help us figure it out.
            </div>
          </div>

          <div className="labs__img-holder">
            <img
              src={billsbot_and_scientist_lab}
              className="labs__img"
              alt="Billsbot and Scientist Lab"
            />
          </div>
        </div>
      </section>
      {/* End Billsby Labs Section */}
    </main>
  );
};

export default Landing;
