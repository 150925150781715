// Dependencies
import React, { createContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

// Import Packages, Modules, Helpers
import auth from '../helpers/Auth';
import API_PATH from '../helpers/APIPaths';
import ImageUploadHelper from '../helpers/ImageUploadHelper';
import { dummyPageData } from '../lib/DummyPageData';
import PageDataAPISync from '../lib/PageDataAPISync';
import { PAGE_API_KEY } from '../lib/env';
// import SocketIO from "../lib/Socket";

export const Context = createContext();

let __tempQueueList = [];

export const ContextProvider = ({ children }) => {
  let history = useHistory();

  let companyName = '';
  let baseURL = '';
  if (localStorage.getItem('authenticated') === 'true') {
    let billsby_url = localStorage.getItem('billsbyURL');
    billsby_url = billsby_url.split('.');
    companyName = billsby_url[0];
    baseURL = `https://${companyName}.subscription.page`;
  }

  /* ------------------------------ Global States ----------------------------- */

  // Page States
  const [pageData, setPageData] = useState({
    metadata_title: '',
    metadata_description: '',
    color_scheme: dummyPageData.color_scheme,
    logo: {
      name: 'logo',
      type: '',
      src: '',
      uploaded: false
    },
    background_image: {
      name: 'background',
      type: '',
      src: '',
      uploaded: false
    },
    heading_text: '',
    subscribe_button_text: '',
    manage_account_text: '',
    sales_pitch: ``,
    gallery: [],
    faqs: [
      {
        title: '',
        answer: ''
      }
    ],
    terms: ``,
    privacy: ``,
    footer_content: {
      fb_url: '',
      twitter_url: '',
      instagram_url: '',
      copyright_notice: '',
      email_address: ''
    },
    logo_alt: dummyPageData.logo.src,
    background_image_alt: dummyPageData.background_image.src,
    gallery_alt: [...dummyPageData.gallery_alt],
    gallery_thumbnails_alt: dummyPageData.gallery_thumbnails_alt,
    gallery_preview: dummyPageData.gallery_preview.src
  });
  const [isLoadedPageContent, setIsLoadedPageContent] = useState(false);

  // Toast States
  const [toastList, setToastList] = useState([]);

  // Login Error States
  const [signInError, setSigninError] = useState('');

  // Upload Percentage States
  const [uploadPercentage, setUploadPercentage] = useState({
    image_logo: 0,
    image_background: 0,
    image_gallery_1: 0,
    image_gallery_2: 0,
    image_gallery_3: 0,
    image_gallery_4: 0
  });
  const [logoUploadPercent, setLogoUploadPercent] = useState(0);
  const [backgroundUploadPercent, setBackgroundUploadPercent] = useState(0);
  const [galleryUploadPercent1, setGalleryUploadPercent1] = useState(0);
  const [galleryUploadPercent2, setGalleryUploadPercent2] = useState(0);
  const [galleryUploadPercent3, setGalleryUploadPercent3] = useState(0);
  const [galleryUploadPercent4, setGalleryUploadPercent4] = useState(0);

  const [show404Page, setShow404Page] = useState(false);
  const [isPageContentFulllyLoaded, setIsPageContentFullyLoaded] =
    useState(false);

  // Queue State
  const [onQueue, setOnQueue] = useState(0);

  /* --------------------------- Load Data Functions -------------------------- */

  // Load Page Data
  const loadPageData = async () => {
    await axios
      .get(API_PATH.fetch_page_data, auth.getAuthConfig())
      .then(({ data }) => {
        if (data.page_data.heading_text === undefined) {
          console.log('No Page Data Loaded');
          setPageData(dummyPageData);
        } else {
          console.log('Loaded Page Data');
          const updatedData = PageDataAPISync.syncPageData(
            pageData,
            data.page_data
          );
          setPageData(updatedData);
        }
        loadImages();
      })
      .catch((err) => console.log(err));
  };

  // Load Images
  const loadImages = async () => {
    return axios
      .get(API_PATH.fetch_image_all, auth.getAuthConfig())
      .then(({ data }) => {
        console.log('Loaded Page Data Images');

        const updated_images = PageDataAPISync.syncImages(pageData, data);

        console.log(updated_images);

        setPageData((oldPageData) => {
          console.log(oldPageData);
          setIsLoadedPageContent(true);
          return {
            ...oldPageData,
            logo: updated_images.__logo,
            background_image: updated_images.__background,
            gallery: updated_images.__gallery
          };
        });
      })
      .catch((err) => console.log(err));
  };

  /* ----------------------- Load Customer Page Content ----------------------- */

  const loadMainPage = async (company_name) => {
    // Get Page content first
    let updated_data;

    const path = history.location.pathname.split('/')[1] || 'main';

    console.log(path);

    // Load Content
    return await axios
      .get(
        path === 'main' || path === 'terms' || path === 'privacy'
          ? `${API_PATH.fetch_all_content}?q=${company_name}`
          : `${API_PATH.fetch_custompages_content}?q=${company_name}&path=${path}`,
        {
          headers: {
            api_key: PAGE_API_KEY
          }
        }
      )
      .then(async ({ data }) => {
        console.log('Loaded data');
        if (data.page_data.length === 0) {
          console.log('Show 404');
          setShow404Page(true);
          return 'Redirect404';
        } else {
          updated_data = PageDataAPISync.syncDataToState(
            pageData,
            data.page_data
          );
          setPageData(updated_data);
          setIsLoadedPageContent(true);
          setIsPageContentFullyLoaded(true);
          return 'Show Data';
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoadedPageContent(true);
        setIsPageContentFullyLoaded(true);
        setShow404Page(true);
        return 'Redirect404';
      });
    /* ------------------ Load Images using ImageLoader Module ------------------ */
  };

  /* ------------------------- Send and Save page data ------------------------ */

  const savePageChanges = () => {
    console.log(pageData);

    const post_data = {
      metadata_title: pageData.metadata_title,
      metadata_description: pageData.metadata_description,
      color_scheme: pageData.color_scheme,
      heading_text: pageData.heading_text,
      subscribe_button_text: pageData.subscribe_button_text,
      manage_account_text: pageData.manage_account_text,
      sales_pitch: pageData.sales_pitch,
      faqs: JSON.stringify(pageData.faqs)
    };
    axios
      .post(API_PATH.save_page_data, post_data, auth.getAuthConfig())
      .then(({ data }) => {
        console.log(data);
        // alert(data.message);
        createToast('success', 'Changes saved successfully');
      })
      .catch((err) => {
        console.log(err);
        createToast('error', 'Something went wrong');
      });
  };

  const saveTermsAndConditions = () => {
    axios
      .post(
        API_PATH.save_terms_and_conditions,
        {
          terms_and_conditions: pageData.terms
        },
        auth.getAuthConfig()
      )
      .then(({ data }) => {
        console.log(data);
        createToast('success', 'Changes saved successfully');
      })
      .catch((err) => {
        createToast('error', 'Something went wrong');
        console.log(err);
      });
  };

  const savePrivacyPolicy = () => {
    axios
      .post(
        API_PATH.save_privacy_policy,
        {
          privacy_policy: pageData.privacy
        },
        auth.getAuthConfig()
      )
      .then(({ data }) => {
        console.log(data);
        createToast('success', 'Changes saved successfully');
      })
      .catch((err) => {
        createToast('error', 'Something went wrong');
        console.log(err);
      });
  };

  const saveFooterContent = () => {
    axios
      .post(
        API_PATH.save_footer,
        {
          facebook_url: pageData.footer_content.fb_url,
          twitter_url: pageData.footer_content.twitter_url,
          instagram_url: pageData.footer_content.instagram_url,
          copyright_notice: pageData.footer_content.copyright_notice,
          email_address: pageData.footer_content.email_address
        },
        auth.getAuthConfig()
      )
      .then(({ data }) => {
        console.log(data);
        createToast('success', 'Changes saved successfully');
      })
      .catch((err) => {
        createToast('error', 'Something went wrong');
        console.log(err);
      });
  };
  // Delete Page Data
  const deletePageData = () => {
    axios
      .delete(API_PATH.delete_page_data, auth.getAuthConfig())
      .then(({ data }) => {
        console.log(data.title, data.message);
        // Sign out after deleting page data
        signOutUser();
      })
      .catch((err) => {
        // let result = err.response.data;
        console.log(err);
      });
  };

  /* --------------------------- USER AUTHENTICATION -------------------------- */

  const signInUser = (billsby_url, pages_password, api_key) => {
    setSigninError('');
    if (billsby_url === '.billsby.com') {
      setSigninError("We'll need your Billsby URL to log you in.");
    } else if (pages_password === '') {
      setSigninError(
        'You need to choose a Page password too. Pop one in and then press the login button again.'
      );
    } else {
      axios
        .post(
          API_PATH.sign_in_user,
          {
            billsby_url,
            pages_password,
            api_key
          },
          auth.getAuthConfig()
        )
        .then(({ data }) => {
          let result = data.data;
          if (data.status === 200) {
            auth.login(
              result.token,
              result.api_key,
              result.billsby_url,
              result.isBPPlus,
              result.cid,
              result.sid,
              result.plan_id,
              function () {
                console.log('Authenticated!');
                history.push('/account/pages/main');
                window.location.reload();
              }
            );
          }
        })
        .catch((err) => {
          if (err.response !== undefined) {
            let result = err.response.data;
            if (result.content === 'Password Format Error')
              setSigninError(
                "That password didn't meet our complexity rules. At least eight characters with letters, numbers and symbols please."
              );
            if (api_key === '') {
              if (result.data)
                if (result.data.isValidAPIKey === false) {
                  setSigninError(
                    "That API key doesn't seem like a match for the Billsby URL. Give it another go?"
                  );
                }
            } else {
              if (result.data)
                if (result.data.isValidAPIKey === false) {
                  setSigninError(
                    "That API key doesn't seem like a match for the Billsby URL. Give it another go?"
                  );
                }
            }
          } else {
            setSigninError('Connection Error. Give it another go?');
          }
        });
    }
  };

  const signOutUser = () => {
    // Disconnect socket connection
    // SocketIO.disconnect();

    // Logout user and clear cache
    auth.logout();

    // Redirect to login
    history.replace('/login');
  };

  /* ---------------------------------- Toast --------------------------------- */

  const createToast = (type, title, url) => {
    const id = Math.floor(Math.random() * 100 + 1);
    let toastProperties = {};
    const billsby_url = localStorage.getItem('billsbyURL');
    const url_split = billsby_url.split('.');
    let base_url = process.env.REACT_APP_FE_DOMAIN;
    base_url = base_url.replace('entercompanyname', url_split[0]);
    let companyname_url = base_url;
    if (url) companyname_url = url;

    if (type === 'success') {
      toastProperties = {
        id,
        title: title,
        description: `Why not view your <a class='toast-link' href='${companyname_url}' target='_blank'>updated page</a>`,
        image: 'billsbot-elated'
      };
    } else {
      toastProperties = {
        id,
        title: title,
        description:
          "Let us know if you <a class='toast-link' href='/account/help'>need some help</a>",
        image: 'billsbot-down'
      };
    }

    setToastList([...toastList, toastProperties]);
  };

  const createToastWithDescription = (type, title, description) => {
    const id = Math.floor(Math.random() * 100 + 1);
    let toastProperties = {};
    // const billsby_url = localStorage.getItem("billsbyURL");
    // const url_split = billsby_url.split(".");
    // const companyname_url = `https://${url_split[0]}.subscription.page`;
    if (type === 'success') {
      toastProperties = {
        id,
        title: title,
        description: description,
        image: 'billsbot-elated'
      };
    } else {
      toastProperties = {
        id,
        title: title,
        description: description,
        image: 'billsbot-down'
      };
    }

    setToastList([...toastList, toastProperties]);
  };

  // Delete Toast
  const deleteToast = (id) => {
    let tempList = toastList;
    const index = tempList.findIndex((e) => e.id === id);
    tempList.splice(index, 1);
    setToastList([...tempList]);
  };

  /* ------------------------------ Subscriptions ----------------------------- */

  const getAndSaveSubscription = async () => {
    const cid = localStorage.getItem('cid');
    const sid = localStorage.getItem('sid');

    console.log(cid, sid);

    return await axios
      .post(
        API_PATH.get_and_save_subscription_details,
        { cid, sid },
        auth.getAuthConfig()
      )
      .then(({ data }) => {
        const result = data.result;

        // Update Cache
        localStorage.setItem('isBPPlus', result.isBPPlus ? 'true' : 'false');
        localStorage.setItem('cid', result.cid);
        localStorage.setItem('sid', result.sid);
        localStorage.setItem('plan_id', result.plan_id);

        createToastWithDescription(
          'success',
          'Upgraded to Billsby Pages Plus',
          "Check your <a class='toast-link' href='/account/account' target='_blank'>Account page</a> now!"
        );
        // Redirect to account page
        setTimeout(() => {
          history.push('/account');
        }, 2000);

        return 'success';
      })
      .catch((err) => {
        console.log(err);
        createToastWithDescription(
          'error',
          'Error Upgrading to Billsby Pages Plus',
          'Try contacting support for help'
        );
        // Redirect to account page
        setTimeout(() => {
          history.push('/account/account');
        }, 3000);
      });
  };

  /* ------------------------ Image Saving and Queuing ------------------------ */

  // Image Select and Upload
  const onImageSelect = async (event, page) => {
    // Update the state
    const file = event.target.files[0];
    const image_for = event.target.name;

    if (file) {
      /* ------------------------------ File Checkers ----------------------------- */

      const isFileAnImage = ImageUploadHelper.checkUploadFileType(file);
      const isInMaxSize = ImageUploadHelper.checkUploadMaxSize(file, image_for);
      const image_dimensions = await ImageUploadHelper.checkUploadDimensions(
        file
      );

      if (!isFileAnImage) {
        createToast('error', 'File is not an image');
        return console.log('Selected file is not an image');
      }
      if (!isInMaxSize) {
        createToast('error', 'File size is over the 1 MB');
        return console.log("Selected file's size is over the maximum");
      }

      /* --------------------------- File Upload Process -------------------------- */

      // Http requrest headers and callback
      let options = {
        ...auth.getAuthConfig(),
        onUploadProgress: (progressEvent) => {
          // console.log(progressEvent);
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          // console.log(`${loaded}kb of ${total}kb | ${percent}%`);

          if (percent < 100) {
            if (image_for === 'image_gallery_1') {
              setGalleryUploadPercent1(percent);
            } else if (image_for === 'image_gallery_2') {
              setGalleryUploadPercent2(percent);
            } else if (image_for === 'image_gallery_3') {
              setGalleryUploadPercent3(percent);
            } else if (image_for === 'image_gallery_4') {
              setGalleryUploadPercent4(percent);
            } else if (image_for === 'image_logo') {
              setLogoUploadPercent(percent);
            } else if (image_for === 'image_background') {
              setBackgroundUploadPercent(percent);
            } else {
              //Do nothing
            }
          }
        }
      };

      // Set upload status to uploading

      // Set FormData to send to Upload API
      const fd = new FormData();
      fd.append('FileData', file, file.name);
      fd.append('FileName', file.name);
      fd.append('FileType', file.type);
      fd.append('FileFor', image_for);
      fd.append('FileWidth', image_dimensions.width);
      fd.append('FileHeight', image_dimensions.height);
      fd.append('LinkedPage', page);

      if (image_for === 'image_gallery_1') {
        setGalleryUploadPercent1(0.1);
      } else if (image_for === 'image_gallery_2') {
        setGalleryUploadPercent2(0.1);
      } else if (image_for === 'image_gallery_3') {
        setGalleryUploadPercent3(0.1);
      } else if (image_for === 'image_gallery_4') {
        setGalleryUploadPercent4(0.1);
      } else if (image_for === 'image_logo') {
        setLogoUploadPercent(0.1);
      } else if (image_for === 'image_background') {
        setBackgroundUploadPercent(0.1);
      } else {
        //Do nothing
      }

      const queue = {
        name: image_for,
        data: fd,
        options: options,
        file: file
      };

      __tempQueueList = [...__tempQueueList, queue];
      setOnQueue(1);
    }
  };

  const updateQueueList = () => {
    setTimeout(() => {
      __tempQueueList.shift();
      setOnQueue(0);
      return;
    }, 2000);
  };

  const uploadImage = (queue) => {
    return new Promise(async (resolve, reject) => {
      await axios
        .post(API_PATH.single_image_upload, queue.data, queue.options)
        .then(({ data }) => {
          let for_image = '';
          // let datab64 = `data:${queue.file.type};base64,${btoa(binaryString)}`;
          // let location = `https://storage.googleapis.com/billsby-pages-assets/${companyName}/${companyName}-${queue.name}`;
          let location = data.location;
          // Render Images

          // End -- Render Images

          setTimeout(() => {
            if (data.image_for === 'image_logo') {
              setPageData({
                ...pageData,
                logo: {
                  name: queue.file.name,
                  src: `${location}?${Date.now()}`,
                  type: queue.file.type,
                  uploaded: true,
                  date_loaded: Date.now()
                }
              });
              for_image = 'Logo';
            } else if (data.image_for === 'image_background') {
              for_image = 'Background';
              setPageData({
                ...pageData,
                background_image: {
                  name: queue.file.name,
                  src: `${location}?${Date.now()}`,
                  type: queue.file.type,
                  uploaded: true,
                  date_loaded: Date.now()
                }
              });
            } else {
              for_image = 'Gallery';
              let temp_gallery = [];

              dummyPageData.gallery_alt.forEach((gallery) => {
                let imgData = {...gallery}
                if (data.image_for === imgData.for) {
                  imgData.src = `${location}?${Date.now()}`;
                  imgData.name = queue.file.name;
                  imgData.type = queue.file.type;
                  imgData.uploaded = true;
                  imgData.thumbnail = `${location}_thumbnail?${Date.now()}`;
                  imgData.date_loaded = Date.now();
                }
                temp_gallery.push(imgData);
              });

              console.log(temp_gallery, dummyPageData.gallery_alt);

              // setPageData({ ...pageData, gallery: temp_gallery });
            }

            if (data.image_for === 'image_gallery_1') {
              setGalleryUploadPercent1(100);
            } else if (data.image_for === 'image_gallery_2') {
              setGalleryUploadPercent2(100);
            } else if (data.image_for === 'image_gallery_3') {
              setGalleryUploadPercent3(100);
            } else if (data.image_for === 'image_gallery_4') {
              setGalleryUploadPercent4(100);
            } else if (data.image_for === 'image_logo') {
              setLogoUploadPercent(100);
            } else if (data.image_for === 'image_background') {
              setBackgroundUploadPercent(100);
            } else {
              //Do nothing
            }

            setTimeout(() => {
              if (data.image_for === 'image_gallery_1') {
                setGalleryUploadPercent1(0);
              } else if (data.image_for === 'image_gallery_2') {
                setGalleryUploadPercent2(0);
              } else if (data.image_for === 'image_gallery_3') {
                setGalleryUploadPercent3(0);
              } else if (data.image_for === 'image_gallery_4') {
                setGalleryUploadPercent4(0);
              } else if (data.image_for === 'image_logo') {
                setLogoUploadPercent(0);
              } else if (data.image_for === 'image_background') {
                setBackgroundUploadPercent(0);
              } else {
                //Do nothing
              }
              // setUploadPercentage({ ...uploadPercentage, [image_for]: 0 });
            }, 1000);
            createToast('success', for_image + ' Image uploaded!');
            // window.location.reload();

            updateQueueList();

            resolve({ uploaded: true });
          }, 5000);
        })
        .catch((err) => {
          console.log(err);
          createToast('error', `Error uploading image!`);
          reject({ uploaded: false });
        });
    });
  };

  /* ------------------------------- Use Effects ------------------------------ */

  // Auto delete toast
  useEffect(() => {
    const interval = setInterval(() => {
      if (toastList.length) deleteToast(toastList[0].id);
    }, 10000); // 10sec
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, [toastList]);

  // Auto delete sign in error list
  useEffect(() => {
    const interval = setInterval(() => {
      if (signInError) {
        setSigninError('');
      }
    }, 10000); // 10sec
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, [signInError]);

  // On Page Load
  useEffect(() => {
    if (history.location.search !== '') {
      const params = new URLSearchParams(history.location.search);

      // Set the cid and sid to localStorage and redirect to processing
      localStorage.setItem('sid', params.get('sid'));
      localStorage.setItem('cid', params.get('cid'));

      history.push('/account/processing');
    }

    /* ------------------ Run socket connection when logged in ------------------ */
    // Socket Connection

    // const location = history.location.pathname;

    // if (
    //   location === "/account/account" ||
    //   location === "/account" ||
    //   location === "/account/edit"
    // )

    /* --------------- Temporarily removed -- until further notice -------------- */

    // if (location.includes("/account"))
    //   if (auth.isAuthenticated()) {
    //     SocketIO.initialize();
    //     console.log("running socket");
    //     SocketIO.socket.on("removeBPPlusToClient", (data) => {
    //       console.log(data.message);
    //       if (localStorage.getItem("cid") === data.customer_id) {
    //         localStorage.setItem("isBPPlus", "false");
    //         localStorage.setItem("cid", null);
    //         localStorage.setItem("sid", null);
    //         localStorage.setItem("plan_id", null);
    //         createToastWithDescription(
    //           "error",
    //           "Unsubscribed Billsby Pages Plus",
    //           "Plus subscription was cancelled"
    //         );
    //         // if (location === "/account/account") history.push("/account");
    //       }
    //     });

    //     // CLEAN UP THE EFFECT
    //     return () => SocketIO.disconnect();
    //     //
    //   }
    // eslint-disable-next-line
  }, []);

  // Queue Management
  useEffect(() => {
    if (onQueue === 1) {
      uploadImage(__tempQueueList[0]).then((result) => console.log(result));
    } else {
      if (__tempQueueList.length > 0) {
        setOnQueue(1);
      }
    }
    // eslint-disable-next-line
  }, [onQueue]);

  /* --------------------------------- Return --------------------------------- */

  return (
    <Context.Provider
      value={{
        companyName,
        baseURL,
        pageData,
        setPageData,
        onImageSelect,
        loadPageData,
        savePageChanges,
        saveTermsAndConditions,
        savePrivacyPolicy,
        saveFooterContent,
        signInUser,
        signOutUser,
        deletePageData,
        toastList,
        setToastList,
        createToast,
        deleteToast,
        loadMainPage,
        isLoadedPageContent,
        signInError,
        setSigninError,
        uploadPercentage,
        setUploadPercentage,
        show404Page,
        setShow404Page,
        isPageContentFulllyLoaded,
        getAndSaveSubscription,
        logoUploadPercent,
        backgroundUploadPercent,
        galleryUploadPercent1,
        galleryUploadPercent2,
        galleryUploadPercent3,
        galleryUploadPercent4,
        onQueue
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default ContextProvider;
