// Dependencies
import React from 'react';
import classNames from 'classnames'
import { Markup } from 'interweave'

const SectionHeader = (props) => {
  return (
    <div className={classNames({
      'section-header': true,
      'section-header--nosub': !props.subtitle
    })}>
      <div className="section-header__title">{props.title}</div>
      { props.subtitle &&
        <div className="section-header__subtitle">
          <Markup content={props.subtitle} />
        </div>
      }
    </div>
  );
};

export default SectionHeader;
