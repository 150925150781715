import React, { useState, useEffect } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fab } from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
import api_paths from "../../helpers/APIPaths";

import { PAGE_API_KEY } from "../../lib/env";

library.add(fab);

const PageFooter = ({ name, colorScheme }) => {
  const [footerData, setFooterData] = useState({
    terms: false,
    privacy: false,
    fb_url: "",
    twitter_url: "",
    instagram_url: "",
    copyright_notice: "",
    email_address: "",
    color_scheme: "",
  });

  const fetchFooterData = () => {
    axios
      .get(api_paths.fetch_footer_content + "?q=" + name, {
        headers: {
          api_key: PAGE_API_KEY,
        },
      })
      .then(({ data }) => {
        if (data) setFooterData(data);
      });
  };

  useEffect(() => {
    fetchFooterData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {(footerData.fb_url ||
        footerData.twitter_url ||
        footerData.instagram_url ||
        footerData.copyright_notice ||
        footerData.email_address ||
        footerData.terms ||
        footerData.terms !== "" ||
        footerData.privacy ||
        footerData.terms !== "") && (
        <footer
          className="page-footer"
          style={{
            backgroundColor: colorScheme || footerData.color_scheme,
          }}
        >
          <div className="page-footer__content">
            {(footerData.fb_url ||
              footerData.twitter_url ||
              footerData.instagram_url) && (
              <div className="page-footer__social">
                {footerData.fb_url && (
                  <a href={footerData.fb_url}>
                    <FontAwesomeIcon icon={["fab", "facebook-square"]} />
                  </a>
                )}

                {footerData.twitter_url && (
                  <a href={footerData.twitter_url}>
                    <FontAwesomeIcon icon={["fab", "twitter-square"]} />
                  </a>
                )}

                {footerData.instagram_url && (
                  <a href={footerData.instagram_url}>
                    <FontAwesomeIcon icon={["fab", "instagram-square"]} />
                  </a>
                )}
              </div>
            )}

            {footerData.copyright_notice && (
              <div className="page-footer__copyright">
                <p>{footerData.copyright_notice}</p>
              </div>
            )}

            <div className="page-footer__links">
              {footerData.terms && <a href="/terms">Terms and conditions</a>}
              {footerData.privacy && <a href="/privacy">Privacy policy</a>}
              {footerData.email_address && (
                <a href={`mailto:${footerData.email_address}`}>
                  {footerData.email_address}
                </a>
              )}
            </div>
          </div>
        </footer>
      )}
    </>
  );
};

export default PageFooter;
