// Dependencies
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

// Components
import SectionHeader from '../../components/builder/SectionHeader';
import BuilderChart from '../../components/builder/BuilderChart';
import axios from 'axios';
import api_paths from '../../helpers/APIPaths';

const PageVisitors = () => {
  const [dataStats, setDataStats] = useState([]);
  const [underMaintainance, setUnderMaintenance] = useState(false);
  const billsby_url = localStorage.getItem('billsbyURL');
  const url_split = billsby_url.split('.');
  const companyname_url = `${url_split[0]}.subscription.page`;
  let simple_analytics_url = `https://simpleanalytics.com/${companyname_url}.json`;

  Date.shortMonths = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  function short_months(dt) {
    return `${Date.shortMonths[dt.getMonth()]} ${dt.getDate()}`;
  }

  useEffect(() => {
    let today_date = new Date();
    let visitData = JSON.parse(localStorage.getItem('pageVisits'));

    if (visitData)
      setDataStats(() => {
        const __data = visitData.data.visits.map((visit) => {
          return {
            label: short_months(new Date(visit.date)),
            value: visit.pageviews
          };
        });

        console.log(__data);

        return __data;
      });

    axios
      .get(api_paths.cors_solver + '?url=' + simple_analytics_url)
      .then(({ data }) => {
        if (data.error) {
          throw data.error;
        }
        if (data.visits) {
          // Set State
          setDataStats(() => {
            const __data = data.visits.map((visit) => {
              return {
                label: short_months(new Date(visit.date)),
                value: visit.pageviews
              };
            });

            console.log(__data);

            return __data;
          });

          // Save OR Update cache
          visitData = {
            date_updated: today_date,
            data: data
          };
          localStorage.setItem('pageVisits', JSON.stringify(visitData));
        } else {
          console.log('Page statistics error', data);
        }
      })
      .catch((err) => {
        setUnderMaintenance(true);
        console.log(err);
      });

    //eslint-disable-next-line
  }, []);

  return (
    <main className='statistics-page'>
      <Helmet>
        <title>
          Account | Billsby Pages | Simple landing pages for your subscription
          business
        </title>
        <meta name='description' content='Manage your Billsby Pages account' />
      </Helmet>

      <section className='section-statistics'>
        <div className='billsby-container'>
          <SectionHeader title={'Page visitors (last 30 days)'} />

          {underMaintainance ? (
            <div>
              Ooops this page is under maintainance! We will be back. Thank
              you!
            </div>
          ) : (
            <div>
              {dataStats && dataStats.length > 0 ? (
                <div className='content-wrapper'>
                  <BuilderChart data={dataStats} />
                </div>
              ) : (
                <p className='builder-text'>
                  Loading page visit statistics ...
                </p>
              )}
            </div>
          )}
        </div>
      </section>
    </main>
  );
};

export default PageVisitors;
