// Dependencies
import React, { useEffect, useState, useRef } from "react";
import shortid from "shortid";
// import Interweave, { Markup } from "interweave";
// import renderHTML from "react-render-html";

// Components
import SectionHeader from "../../components/builder/SectionHeader";
import BuilderTabs from "../../components/builder/BuilderTabs";
import axios from "axios";
import API_PATH from "../../helpers/APIPaths";
import HTMLRenderer from "../../components/builder/HTMLRenderer";

const Help = () => {
  const [content, setContent] = useState(null);
  const [finalData, setFinalData] = useState([]);
  // const [renderContent, setRenderContent] = useState(false);

  const divRef = useRef();

  let readme_url = `https://dash.readme.io/api/v1/docs/billsby-pages`;

  const createMarkup = (text) => {
    // console.log(text);
    return { __html: text };
  };

  const assembleReadMeData = () => {
    if (divRef.current) {
      const allElements = divRef.current.children;
      // console.log(allElements);
      let finalData = [];
      let isAnotherTab = false;
      let order = 0;
      let tabNumber = -1;
      let objectToAdd = {};

      Array.from(allElements).forEach(function (element, idx) {
        let currentClassName = element.className;
        // get the header
        if (currentClassName === "magic-block-api-header") {
          isAnotherTab = true;
          objectToAdd.headerText =
            element.querySelectorAll("h1")[0].innerText.trim() +
            "." +
            shortid.generate();
        }

        // get body texts
        if (currentClassName === "magic-block-textarea") {
          isAnotherTab = false;
          // const regex = /<p[^>]*>/gi;
          // const result = element.innerHTML.replace(
          //   regex,
          //   '<div class="text_body">'
          // );
          // console.log(result);
          let bodyText = element.innerHTML;
          let newContent = {};
          newContent.type = "text";
          newContent.order = order;
          // bodyText = bodyText.replace(
          //   /<p[^>]*>/gi,
          //   "<div class='text-paragraph'>"
          // );
          // bodyText = bodyText.replace(/<\/p[^>]*>/gi, "</div>");
          newContent.text = bodyText;

          // console.log(bodyText);

          if (finalData[tabNumber].tabContent) {
            finalData[tabNumber].tabContent.push(newContent);
          } else {
            finalData[tabNumber].tabContent = [newContent];
          }
        }

        // get images
        if (currentClassName === "magic-block-image") {
          isAnotherTab = false;
          let url = element.querySelectorAll("figure a img")[0].src.trim();
          let caption = element
            .querySelectorAll("figcaption p")[0]
            .innerText.trim();

          let newContent = {};
          newContent.type = "image";
          newContent.order = order;
          newContent.url = url;
          newContent.caption = caption;

          if (finalData[tabNumber].tabContent) {
            finalData[tabNumber].tabContent.push(newContent);
          } else {
            finalData[tabNumber].tabContent = [newContent];
          }
        }

        // get tables
        if (currentClassName === "magic-block-parameters") {
          isAnotherTab = false;
          let rowList = element.querySelectorAll(
            ".block-parameters-table .table .tr"
          );
          let headList = element.querySelectorAll(
            ".block-parameters-table .table .tr .th"
          );
          let newContent = {};
          newContent.type = "table";
          newContent.rows = [];
          newContent.thead = Array.prototype.map.call(headList, (th) =>
            th.innerHTML.trim()
          );
          newContent.order = order;

          Array.from(rowList).forEach(function (tr, idx) {
            if (idx === 0) return;
            let colList = tr.querySelectorAll(".td p");
            newContent.rows.push({
              cols: Array.prototype.map.call(colList, (td, idx) => {
                let newCol = {};
                newCol.colText = td.innerHTML.trim();
                newCol.colLabel = headList[idx].innerHTML.trim();

                return newCol;
              }),
            });
          });

          if (finalData[tabNumber].tabContent) {
            finalData[tabNumber].tabContent.push(newContent);
          } else {
            finalData[tabNumber].tabContent = [newContent];
          }
        }

        if (isAnotherTab) {
          finalData.push(objectToAdd);
          tabNumber = ++tabNumber;
          order = 0;
          objectToAdd = {};
        } else {
          order++;
        }
      });

      const compare = function (a, b) {
        return parseInt(a.order) - parseInt(b.order);
      };

      if (finalData.tabContent) {
        finalData.tabContent.sort(compare);
      }

      // console.log(finalData);
      return finalData;
    }
    return null;
  };

  useEffect(() => {
    axios
      .post(API_PATH.base_endpoint + "/proxy/get-url", {
        url: readme_url,
        headers: {
          headers: {
            authorization: "Basic TwpAmiTMhSmp3w8ffgq7qfc56FiC0pOc",
            "Access-Control-Allow-Origin": "*",
          },
        },
      })
      .then(({ data }) => {
        let bodyHTML = data.body_html;
        bodyHTML = bodyHTML.replace(
          /<p[^>]*>/gi,
          "<div class='text-paragraph'>"
        );
        bodyHTML = bodyHTML.replace(/<\/p[^>]*>/gi, "</div>");
        setContent(bodyHTML);
        // console.log(bodyHTML);
        setFinalData(assembleReadMeData());
      })
      .catch((err) => console.log(err));
    //eslint-disable-next-line
  }, []);

  return (
    <main className="help-page">
      <section className="section-url">
        <div className="billsby-container">
          <SectionHeader
            title={"Help"}
            subtitle={
              'If you need help using Billsby Pages, we\'re here for you. If the help content below doesn\'t fix your issue, you can email <a href="mailto:hello@billsby.com" target="_blank">hello@billsby.com</a>.'
            }
          />

          <div className="content-wrapper">
            <div
              ref={divRef}
              dangerouslySetInnerHTML={createMarkup(content)}
              style={{ display: "none", overflow: "hidden", height: "0" }}
            />

            {finalData && finalData.length > 0 ? (
              <BuilderTabs>
                {finalData.map((tab, idx) => (
                  <div key={idx} label={tab.headerText}>
                    {tab.tabContent &&
                      tab.tabContent.map((currentContent, idx) => {
                        if (currentContent.type === "text") {
                          return (
                            <HTMLRenderer
                              content={currentContent.text}
                              key={idx}
                            />
                          );
                        }

                        if (currentContent.type === "image") {
                          return (
                            <div key={idx} className="builder-post-image">
                              <div className="builder-post-image__image-holder">
                                <img
                                  className="builder-post-image__img"
                                  src={currentContent.url}
                                  alt="post_image"
                                />
                              </div>
                              {currentContent.caption && (
                                <div className="builder-post-image__caption">
                                  <p className="builder-text">
                                    {currentContent.caption}
                                  </p>
                                </div>
                              )}
                            </div>
                          );
                        }

                        if (currentContent.type === "table") {
                          return (
                            <table key={idx} className="builder-table">
                              <thead>
                                <tr>
                                  {currentContent.thead.map((th, idx) => {
                                    return (
                                      <th key={idx} scope="col">
                                        {th}
                                      </th>
                                    );
                                  })}
                                </tr>
                              </thead>
                              <tbody>
                                {currentContent.rows.map((row, idx) => {
                                  return (
                                    <tr key={idx}>
                                      {row.cols.map((col, idx) => {
                                        return (
                                          <td
                                            key={idx}
                                            data-label={col.colLabel}
                                          >
                                            {col.colText}
                                          </td>
                                        );
                                      })}
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          );
                        }

                        return "";
                      })}
                  </div>
                ))}
              </BuilderTabs>
            ) : (
              <p className="builder-text">Loading help content...</p>
            )}
          </div>
        </div>
      </section>
    </main>
  );
};

export default Help;
