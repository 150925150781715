import React from "react";
import renderHTML from "react-render-html";

const HTMLRenderer = ({ content }) => {
  //   useEffect(() => {
  //     if (content) console.log(content);
  //   }, [content]);
  return <div>{renderHTML(content)}</div>;
};

export default HTMLRenderer;
