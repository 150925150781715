import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import BillsbyProductModal from "../../components/builder/BillsbyProductModal";

import auth from "../../helpers/Auth";
import API_PATH from "../../helpers/APIPaths";

// Context
import { Context } from "../../context/Context";

const LinkProduct = ({ page }) => {
  const { createToast } = useContext(Context);

  const [products, setProducts] = useState([]);
  const [isLinkProductDisabled, setIsLinkProductDisabled] = useState(true);
  const [linkedProduct, setLinkedProduct] = useState({
    linked: false,
    linked_product: {
      product_id: null,
      plan_id: null,
      cycle_id: null,
    },
    data: {},
  });
  const [showModal, setShowModal] = useState(false);

  const fetchProducts = async () => {
    await axios
      .get(API_PATH.get_product_plans + `?q=${page}`, auth.getAuthConfig())
      .then(({ data }) => {
        setProducts(data.productList);
        if (data.linkedProduct !== null) {
          setLinkedProduct({
            linked: true,
            linked_product: {
              product_id: data.linkedProduct.product_id,
              plan_id: data.linkedProduct.plan_id,
              cycle_id: data.linkedProduct.cycle_id,
            },
            data: {},
          });
        } else {
          setIsLinkProductDisabled(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLinkProductDisabled(true);
      });
  };

  const handleLinkProduct = async (linked) => {
    console.log(linked);
    axios
      .post(
        API_PATH.link_product + `?q=${page}`,
        {
          productId: linked?.product?.value?.productId || null,
          planId: linked?.plan?.value?.planId || null,
          cycleId: linked?.cycle?.value?.cycleId || null,
        },
        auth.getAuthConfig()
      )
      .then((result) => {
        setLinkedProduct({
          linked: true,
          linked_product: {
            product_id: linked?.product?.value?.productId || null,
            plan_id: linked?.plan?.value?.planId || null,
            cycle_id: linked?.cycle?.value?.cycleId || null,
          },
          data: linked,
        });
        setShowModal(false);
        createToast("success", "Linked Product successfully");
      })
      .catch((err) => {
        console.log(err);
        createToast("error", "Error Linking Product");
        console.warn("Error linking");
      });
  };

  // const handleUnlinkProduct = () => {
  //   axios
  //     .delete(API_PATH.unlink_product + `?q=${page}`, auth.getAuthConfig())
  //     .then((result) => {
  //       setLinkedProduct({
  //         linked: false,
  //         linked_product: {
  //           productId: null,
  //           planId: null,
  //           cycleId: null,
  //         },
  //         data: {},
  //       });
  //       setShowModal(false);
  //       createToast("success", "Unlinked Product Successfully");
  //     })
  //     .catch((err) => {
  //       createToast("success", "Unlinked Product Failed");
  //       console.warn("Error unlinking");
  //     });
  // };

  const openProductModal = () => {
    setShowModal(true);
  };

  const linkText = (linked_product) => {
    if (
      linked_product.product_id &&
      !linked_product.plan_id &&
      !linked_product.cycle_id
    )
      return `Linked to Product ${linked_product.product_id}`;
    else if (
      linked_product.product_id &&
      linked_product.plan_id &&
      !linked_product.cycle_id
    )
      return `Linked to Plan ${linked_product.plan_id}`;
    else if (
      linked_product.product_id &&
      linked_product.plan_id &&
      linked_product.cycle_id
    )
      return `Linked to Cycle ${linked_product.cycle_id}`;
    else return "Showing All My Products";
  };

  useEffect(() => {
    fetchProducts();
    setIsLinkProductDisabled(false);
    //eslint-disable-next-line
  }, []);

  return (
    <>
      {isLinkProductDisabled ? (
        <span className="builder-column-caption">
          * To enable <b>Link to a specific product</b> feature, login again
          with your API Key *
        </span>
      ) : (
        <button
          className={`builder-btn builder-btn--full btn ${
            !linkedProduct.linked ? "btn--orange" : "btn--green"
          }`}
          onClick={openProductModal}
          disabled={isLinkProductDisabled}
        >
          {!linkedProduct.linked && "Link to a specific product"}
          {linkedProduct.linked && (
            <span>
              {linkText(linkedProduct.linked_product)}
              {" -"} <u>Change</u>
            </span>
          )}
        </button>
      )}
      <BillsbyProductModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        data={products}
        handleLinkProduct={handleLinkProduct}
        values={linkedProduct.linked_product}
      />
    </>
  );
};

export default LinkProduct;
