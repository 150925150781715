import React from "react";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="copyright billsby-container">
        <div className="copyright__text">© Billsby (2021)</div>
      </div>
    </footer>
  );
};

export default Footer;
