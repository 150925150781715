// Dependencies
import React, { useState, useEffect } from "react";

// Components
import BuilderTab from "./BuilderTab";

const BuilderTabs = (props) => {
  const [activeTab, setActiveTab] = useState(null);
  const isMoreThanOne = React.Children.count(props.children) > 1;

  useEffect(() => {
    setActiveTab(
      isMoreThanOne ? props.children[0].props.label : props.children.props.label
    );
    // eslint-disable-next-line
  }, []);

  const onClickTabItem = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="builder-tabs">
      <div className="builder-tabs__list">
        {isMoreThanOne ? (
          props.children.map((child) => {
            const { label } = child.props;

            return (
              <BuilderTab
                activeTab={activeTab}
                key={label}
                label={label}
                onClick={onClickTabItem}
              />
            );
          })
        ) : (
          <BuilderTab
            activeTab={activeTab}
            key={props.children.props.label}
            label={props.children.props.label}
            onClick={onClickTabItem}
          />
        )}
      </div>
      <div className="builder-tabs__content">
        {isMoreThanOne
          ? props.children.map((child) => {
              if (child.props.label !== activeTab) return undefined;
              return child.props.children;
            })
          : props.children.props.children}
      </div>
    </div>
  );
};

export default BuilderTabs;
