// Sample Assets
import sample_logo from "../assets/images/preview-header-logo.png";
import sample_header_bg from "../assets/images/sample_header_bg.png";
import sample_image_one from "../assets/images/sample_image_one.png";
import sample_image_two from "../assets/images/sample_image_two.png";
import sample_image_three from "../assets/images/sample_image_three.png";
import sample_image_four from "../assets/images/sample_image_four.png";

import preview_gallery_image from "../assets/images/preview-gallery-image.png";

export const dummyPageData = {
  metadata_title: "Subscribe to [Company Name]",
  metadata_description: "",
  color_scheme: "#243F5C",
  gallery_preview: {
    name: "preview_gallery",
    type: "image/png",
    src: preview_gallery_image,
    uploaded: false,
  },
  logo: {
    name: "logo",
    type: "image/svg",
    src: sample_logo,
    uploaded: false,
  },
  background_image: {
    name: "background",
    type: "image/svg",
    src: sample_header_bg,
    uploaded: false,
  },
  heading_text:
    "A short line of text to sell your subscription to the customer",
  subscribe_button_text: "Subscribe today",
  manage_account_text: "Login to existing account",
  sales_pitch: `<h1>Enter your sales pitch here</h1><p>You can use headings, quotes, <u>underline things for effect</u></p><ol><li>You can even use lists</li><li>To really make your point</li></ol>
  `,
  gallery: [],
  gallery_thumbnails: [],
  gallery_alt: [
    {
      name: "image_gallery_1",
      type: "image/svg",
      for: "image_gallery_1",
      src: sample_image_one,
      thumbnail: sample_image_one,
      uploaded: false,
    },
    {
      name: "image_gallery_2",
      type: "image/svg",
      for: "image_gallery_2",
      src: sample_image_two,
      thumbnail: sample_image_two,
      uploaded: false,
    },
    {
      name: "image_gallery_3",
      type: "image/svg",
      for: "image_gallery_3",
      src: sample_image_three,
      thumbnail: sample_image_three,
      uploaded: false,
    },
    {
      name: "image_gallery_4",
      type: "image/svg",
      for: "image_gallery_4",
      src: sample_image_four,
      thumbnail: sample_image_four,
      uploaded: false,
    },
  ],

  faqs: [
    {
      title: "Why should I subscribe?",
      answer:
        "You can have as many FAQs as you'd like. Use them to: <ul><li>Answer Questions</li><li>Remind people why they should subscribe</li><li>Overcome objections</li></ul>",
    },
  ],
  terms: ``,
  privacy: ``,
  footer_content: {
    fb_url: "",
    twitter_url: "",
    instagram_url: "",
    copyright_notice: "",
    email_address: "",
  },
};

export const dummyCustomPageData = {
  metadata_title: "",
  metadata_description: "",
  color_scheme: dummyPageData.color_scheme,
  logo: {
    name: "logo",
    type: "",
    src: "",
    uploaded: false,
  },
  background_image: {
    name: "background",
    type: "",
    src: "",
    uploaded: false,
  },
  heading_text:
    "A short line of text to sell your subscription to the customer",
  subscribe_button_text: "Subscribe today",
  manage_account_text: "Login to existing account",
  sales_pitch: `<h1>Enter your sales pitch here</h1><p>You can use headings, quotes, <u>underline things for effect</u></p><ol><li>You can even use lists</li><li>To really make your point</li></ol>`,
  gallery: [...dummyPageData.gallery_alt],
  faqs: [
    {
      title: "Why should I subscribe?",
      answer:
        "You can have as many FAQs as you'd like. Use them to: <ul><li>Answer Questions</li><li>Remind people why they should subscribe</li><li>Overcome objections</li></ul>",
    },
  ],
  logo_alt: dummyPageData.logo.src,
  background_image_alt: dummyPageData.background_image.src,
  gallery_alt: [...dummyPageData.gallery_alt],
  gallery_thumbnails_alt: dummyPageData.gallery_thumbnails_alt,
  gallery_preview: dummyPageData.gallery_preview.src,
};
