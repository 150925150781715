//Dependencies
import React from "react";
import { Route } from "react-router-dom";

// Components
// import Header from "../components/builder/Header";
// import Footer from "../components/builder/Footer";

const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <React.Fragment>
      {/* <Header mode="transparent" /> */}
      <Route
        {...rest}
        render={(props) => {
          return <Component {...props} />;
        }}
      />
      {/* <Footer /> */}
    </React.Fragment>
  );
};

export default PublicRoute;
