// Dependencies
import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/builder/Header";
import { useLocation } from "react-router-dom";

// import bg_404 from "../../assets/images/404-bg.png";
// import bg_404_2x from "../../assets/images/404-bg@2x.png";
import img_404 from "../../assets/images/404 image.png";
// import img_404_2x from "../../assets/images/404-img@2x.png";

// Components
import SectionHeader from "../../components/builder/SectionHeader";
import Footer from "../../components/builder/Footer";
import NoPathYet from "../../components/billsby-page/NoPathYet";

const Is404 = () => {
  const currentRoute = useLocation().pathname;

  let host = window.location.host;

  const gohome = () => {
    if (currentRoute === "/") return;
    window.location.href = "https://pages.billsby.com";
  };

  return (
    <>
      {host === "pages.billsby.com" || host === "localhost:3000" ? (
        <>
          <Header />
          <main className="page-404">
            <Helmet>
              <title>
                404 | Billsby Pages | Simple landing pages for your subscription
                business
              </title>
              <meta
                name="description"
                content="Manage your Billsby Pages account"
              />
            </Helmet>
            <section className="section-404">
              <div className="billsby-container container-404">
                <div className="img-holder-404">
                  <img
                    className="img-404"
                    srcSet={`${img_404} 1x, ${img_404} 2x`}
                    src={img_404}
                    alt="404-img"
                  />
                </div>
                <SectionHeader title={"404: There's nothing to see here"} />
                <button className="btn btn--orange" onClick={gohome}>
                  Go home
                </button>
              </div>
            </section>
          </main>
          <Footer />
        </>
      ) : (
        <>
          <Helmet>
            <title>
              404 | Billsby Pages | Simple landing pages for your subscription
              business
            </title>
            <meta
              name="description"
              content="Manage your Billsby Pages account"
            />
          </Helmet>
          <NoPathYet />
        </>
      )}
    </>
  );
};

export default Is404;
