import React from 'react';
import SectionHeader from '../SectionHeader';
import LightBox from '../../shared/LightBox';
import UploadButton from '../UploadButton';

const Gallery = ({ customPageData, pathName, removeImage }) => {
  return (
    <section className='section-gallery'>
      <div className='billsby-container'>
        <SectionHeader title={'The gallery'} />

        <div className='content-wrapper'>
          <div className='gallery-upload'>
            {/* Assure state is properly loaded before rendering gallery images */}
            {customPageData &&
              customPageData.gallery.map((img, idx) => {
                return (
                  <div key={idx} className='gallery-upload__holder'>
                    <div className='gallery-upload__img-holder'>
                      {/* Show Image Place Holder */}
                      {!img.uploaded && (
                        <img
                          className='preview_gallery_image'
                          src={customPageData.gallery_preview}
                          alt='preview'
                        />
                      )}
                      {/* Show Image Place Holder - end */}

                      <LightBox
                        data={img}
                        className={'gallery-upload__img-clickable'}
                      />
                    </div>
                    <UploadButton
                      index={idx + 1}
                      image_for={img.for}
                      page={pathName}
                      img={img}
                      removeImage={removeImage}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Gallery;
