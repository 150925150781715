// Dependencies
import React, { useContext } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useLocation, useHistory } from "react-router-dom";
import { Context } from "../../context/Context";

// Components
import HeaderButton from "./HeaderButton";

// Shared
import Image from "../shared/Image";

// Helpers
import auth from "../../helpers/Auth";

// Assets
import logo from "../../assets/images/logo.png";
import logo_retina from "../../assets/images/logo@2x.png";
import logo_white from "../../assets/images/logo_white.png";
import logo_white_retina from "../../assets/images/logo_white@2x.png";

const Header = (props) => {
  const { signOutUser } = useContext(Context);
  const currentRoute = useLocation().pathname;
  const history = useHistory();

  const getHeaderButtonText = () => {
    if (currentRoute === "/" || currentRoute === "")
      return auth.get("authenticated")
        ? "My Account"
        : "Create and manage your page";
    else
      return auth.get("authenticated")
        ? "Logout"
        : "Create and manage your page";
  };

  const getImageSource = () => {
    if (currentRoute === "/") {
      return {
        original: logo_white,
        retina: logo_white_retina,
      };
    } else {
      return {
        original: logo,
        retina: logo_retina,
      };
    }
  };

  const handleLogout = () => {
    signOutUser();
  };

  const goToLogin = () => {
    if (currentRoute === "/login") return;
    history.push("/login");
  };
  const goToAccount = () => {
    if (currentRoute === "/account") return;
    history.push("/account");
  };

  const getButtonAction = () => {
    if (currentRoute === "/" || currentRoute === "")
      return auth.get("authenticated") ? goToAccount : goToLogin;
    else return auth.get("authenticated") ? handleLogout : goToLogin;
  };

  const goHome = () => {
    if (currentRoute === "/") return;
    history.push("/");
  };

  return (
    <header
      className={classNames({
        header: true,
        "header--transparent": props.mode === "transparent",
      })}
    >
      <div className="billsby-container">
        <div className="header__content">
          <div className="logo" onClick={goHome}>
            <Image
              className="logo__img"
              imgSrc={getImageSource()}
              altText={"Billsby Logo"}
            />
            <div className="logo__text">Pages</div>
          </div>
          <HeaderButton
            onClick={getButtonAction()}
            text={getHeaderButtonText()}
            isHidden={currentRoute === "/login"}
          />
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  mode: PropTypes.string.isRequired,
};

Header.defaultProps = {
  mode: "fixed",
};

export default Header;
