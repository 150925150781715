// Dependencies
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Table from "../../components/builder/Table";
import PagesModal from "../../components/builder/PagesModal";
import api_paths from "../../helpers/APIPaths";
import Auth from "../../helpers/Auth";
import { Context } from "../../context/Context";

const MyPages = () => {
  const { createToast } = useContext(Context);

  const [pageItems, setPageItems] = useState([
    {
      id: 0,
      name: "Main Page",
      path: "main",
      isEditable: false,
      isDeletable: false,
    },
    {
      id: 0,
      name: "Terms and conditions",
      path: "terms",
      isEditable: false,
      isDeletable: false,
    },
    {
      id: 0,
      name: "Privacy policy",
      path: "privacy",
      isEditable: false,
      isDeletable: false,
    },
  ]);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("add");
  const [dataHolder, setDataHolder] = useState(null);

  const tableHeaders = ["Page name", "Page URL", "Options"];

  const openModal = (type) => {
    setModalType(type);
    setShowModal(true);
  };

  const handleOnClickRename = (type, item) => {
    setDataHolder(item);
    openModal(type);
  };

  const handleDelete = (item) => {
    axios
      .delete(
        api_paths.delete_my_pages + "?id=" + item.id,
        Auth.getAuthConfig()
      )
      .then((result) => {
        setPageItems(pageItems.filter((pi) => pi.id !== item.id));
        createToast("success", `Deleted ${item.name} successfully`);
      })
      .catch((err) => {
        console.log(err.response.data);
        createToast("error", `Failed deleting ${item.name} page`);
      });
  };

  const handleSubmit = (name, path, type) => {
    return new Promise(async (resolve, reject) => {
      console.log(name, path, type);

      if (name === "") {
        console.log("Add a Page name");
        return reject("PAGE_NAME_MISSING");
      }

      if (path === "") {
        console.log("Add a Page URL");
        return reject("PAGE_URL_MISSING");
      }

      if (type === "add") {
        const isExisting = pageItems.find((page) => page.path === path);

        if (isExisting) {
          console.log(isExisting);
          return reject("URL_PATH_TAKEN");
        } else {
          await axios
            .post(
              api_paths.add_my_pages,
              {
                page_name: name,
                page_path: path,
              },
              Auth.getAuthConfig()
            )
            .then(({ data }) => {
              setPageItems([
                ...pageItems,
                {
                  id: data.page_id,
                  name: data.page_name,
                  path: data.page_path,
                  url: data.page_url,
                  isEditable: true,
                  isDeletable: true,
                },
              ]);
              setShowModal(false);
              createToast("success", `Added ${data.page_name} page`);
              resolve("SUCCESS");
            })
            .catch((err) => {
              if (err.response.data.error_code === "URL_PATH_TAKEN") {
                reject(err.response.data.error_code);
              } else {
                setShowModal(false);
                createToast("error", `Error updating ${name} page`);
                reject(err.response.data.error_code);
              }
            });
        }
      } else {
        const isExisting = pageItems.filter(
          (page) => page.id !== dataHolder.id && page.path === path
        );

        console.log(isExisting);

        if (isExisting.length > 0) {
          return reject("URL_PATH_TAKEN");
        } else {
          await axios
            .put(
              api_paths.update_my_pages,
              {
                page_name: name,
                page_path: path,
                page_id: dataHolder.id,
              },
              Auth.getAuthConfig()
            )
            .then(({ data }) => {
              let _pageItems = [...pageItems].map((page) => {
                if (page.id === dataHolder.id) {
                  page.name = data.page_name;
                  page.path = data.page_path;
                  page.url = data.page_url;
                }
                return page;
              });
              setPageItems(_pageItems);
              setShowModal(false);
              createToast("success", `Updated page successfully`);
              resolve("SUCCESS");
            })
            .catch((err) => {
              if (err.response.data.error_code === "URL_PATH_TAKEN") {
                reject(err.response.data.error_code);
              } else {
                setShowModal(false);
                createToast("error", `Error updating ${name} page`);
                reject(err.response.data.error_code);
              }
            });
        }
      }
    });
  };

  const fetchMyPages = () => {
    axios.get(api_paths.get_my_pages, Auth.getAuthConfig()).then(({ data }) => {
      if (data.length > 0) {
        let _customPages = data.map((page) => {
          return {
            id: page.id,
            name: page.page_name,
            path: page.page_path,
            url: page.page_url,
            isEditable: true,
            isDeletable: true,
          };
        });
        setPageItems([...pageItems, ..._customPages]);
      }
    });
  };

  useEffect(() => {
    fetchMyPages();
    //eslint-disable-next-line
  }, []);

  return (
    <main className="my-pages">
      <div className="billsby-container">
        <div className="page-title">Your Pages</div>
        <Table
          tableHeaders={tableHeaders}
          items={pageItems}
          onClickRename={handleOnClickRename}
          onClickDelete={handleDelete}
        />
        <div className="page-add-new">
          <button
            className="btn btn--orange"
            onClick={() => {
              setDataHolder(null);
              openModal("add");
            }}
          >
            Add another page
          </button>
          <PagesModal
            show={showModal}
            handleClose={() => setShowModal(false)}
            type={modalType}
            handleSubmit={handleSubmit}
            data={dataHolder}
          />
        </div>
      </div>
    </main>
  );
};

export default MyPages;
