//Dependencies
import React from "react";
import { Route, Redirect, useHistory } from "react-router-dom";

// Components
import Header from "../components/builder/Header";
import Footer from "../components/builder/Footer";

// Shared
import auth from "./Auth";

const AuthRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();

  let host = window.location.host;
  let parts = host.split(".");
  let path = window.location.pathname;

  if (parts.length >= 3) {
    if (
      host.includes(".localhost:3000") ||
      host.includes(".subscription.page") ||
      host.includes(".staging.subscription.page")
    ) {
      if (
        !path.includes("/terms") ||
        !path.includes("/") ||
        !path.includes("/privacy")
      ) {
        history.push("/404");
      }
    }
  }
  return (
    <React.Fragment>
      <Header />
      <Route
        {...rest}
        render={(props) => {
          if (!auth.isAuthenticated()) {
            return <Component {...props} />;
          } else {
            return (
              <Redirect
                to={{
                  pathname: "/account",
                  state: {
                    from: props.location,
                  },
                }}
              />
            );
          }
        }}
      />
      <Footer />
    </React.Fragment>
  );
};

export default AuthRoute;
