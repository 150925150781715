import React, { useState, useEffect, useContext } from "react";

import { Context } from "../../context/Context";

import BillsbotElated from "../../assets/images/Billsbot-Elated.svg";
import BillsbotDown from "../../assets/images/Billsbot-Down.svg";

const Toast = () => {
  const { toastList, deleteToast } = useContext(Context);
  const position = "top-center";
  const [list, setList] = useState(toastList);
  useEffect(() => {
    setList(toastList);
  }, [toastList, list]);

  const getImage = (image) => {
    if (image === "billsbot-elated") return BillsbotElated;
    else return BillsbotDown;
  };

  const createMarkup = (text) => {
    return { __html: text };
  };
  return (
    <>
      <div className={`notification-container ${position}`}>
        {list.map((toast, i) => (
          <div
            key={i}
            className={`notification toast ${position}`}
            style={{ backgroundColor: "#ffffff" }}
          >
            <div className="notification-image-holder">
              <img
                src={getImage(toast.image)}
                className={`notification-image ${toast.image}`}
                alt="notification"
              />
            </div>
            <div className="notification-content">
              <p className="notification-title">{toast.title}</p>
              <div
                dangerouslySetInnerHTML={createMarkup(toast.description)}
                className="notification-message"
              />
            </div>
            <button onClick={() => deleteToast(toast.id)}>X</button>
          </div>
        ))}
      </div>
    </>
  );
};

export default Toast;
