// Dependencies
import React, { useContext, useEffect } from "react";
import { Markup } from "interweave";

// Contexts
import { Context } from "../../context/Context";

// Components
import PageHeader from "../../components/billsby-page/PageHeader";
import PageFooter from "../../components/billsby-page/PageFooter";

const PageTerms = () => {
  const { pageData, loadMainPage, isLoadedPageContent } = useContext(Context);
  let host = window.location.host;
  let parts = host.split(".");
  let company_name = parts[0];

  useEffect(() => {
    loadMainPage(company_name);
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      {isLoadedPageContent && (
        <>
          {(() => {
            if (typeof window.scanDomBillsby !== "undefined") {
              window.scanDomBillsby();
            }
          })()}
          {pageData.terms ? (
            <div className="pages-app-container">
              <PageHeader data={pageData} />

              <main className="page-terms">
                <section className="section-terms page-container">
                  <div className="section-terms__title">
                    Terms and conditions
                  </div>
                  <div className="section-terms__content">
                    <Markup content={pageData.terms} />
                  </div>
                </section>
              </main>

              <PageFooter name={company_name} />
            </div>
          ) : (
            <>No Terms and Agreement Page found</>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default PageTerms;
