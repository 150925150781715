// Dependencies
import React from 'react';
import PropTypes from 'prop-types';

const Image = (props) => {
  return (
    <React.Fragment>
      <img 
        className={props.className}
        srcSet={`${props.imgSrc.original} 1x, ${props.imgSrc.retina} 2x`} 
        src={`${props.imgSrc.original}`}
        alt={props.altText}
        onClick={props.onClick}
      />
    </React.Fragment>
  )
}

Image.propTypes = {
  imgSrc: PropTypes.objectOf(PropTypes.string).isRequired,
  altText: PropTypes.string,
  className: PropTypes.string,
}

export default Image