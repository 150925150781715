import React from "react";
import BillsbotDown from "../../assets/images/Billsbot-Down.svg";

const NoPathYet = () => {
  const currentPath = window.location.pathname.split("/")[1];

  return (
    <React.Fragment>
      <div className="no-path-container">
        <div className="notification-container">
          <div
            className={`notification toast`}
            style={{ backgroundColor: "#ffffff" }}
          >
            <div className="notification-image-holder">
              <img
                src={BillsbotDown}
                className="notification-image billsbot-down"
                alt="notification"
              />
            </div>
            <div className="notification-content">
              <p className="notification-title">
                This domain doesn't have /{currentPath} yet
              </p>
              <div className="notification-message">
                If it's your site, add and edit pages in your account
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NoPathYet;
