// Dependencies
import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";

// Components
import SectionHeader from "../../components/builder/SectionHeader";

// Context
import { Context } from "../../context/Context";

import { checkPasswordComplexity } from "../../lib/passwordValidation";

const Login = () => {
  const { signInUser, signInError, setSigninError } = useContext(Context);
  const [billsbyURL, setBillsbyURL] = useState("");
  const [pagesPassword, setPagesPassword] = useState("");
  const [apiKey, setApiKey] = useState("");

  const getInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    // console.log(name, value);

    if (name === "billsbyURL") setBillsbyURL(value);
    else if (name === "pagesPassword") setPagesPassword(value);
    else setApiKey(value);
  };

  const processLogin = () => {
    const url = billsbyURL + ".billsby.com";
    const checkedPassword = checkPasswordComplexity(pagesPassword);
    if (checkedPassword.status) {
      setSigninError("");
      signInUser(url, pagesPassword, apiKey);
    } else {
      setSigninError(checkedPassword.error);
    }
  };

  return (
    <main className="login-page">
      <Helmet>
        <title>
          Login | Billsby Pages | Simple landing pages for your subscription
          business
        </title>
        <meta
          name="description"
          content="Login to your Billsby Pages account"
        />
      </Helmet>
      <section className="section-login">
        <div className="billsby-container">
          <SectionHeader title={"Login"} />

          <div className="content-wrapper">
            <p className="builder-text">
              {
                "Logging in to your Billsby Page is easy. We just need your Billsby domain and Pages password. If you're logging in for the first time, or you've forgotten your Pages password, just pop your API key in (Settings > Webhooks and API keys) and you'll be able to reset it."
              }
            </p>

            <div className="builder-row builder-row--two-column">
              <div className="builder-column">
                <div className="builder-input-label">Billsby URL</div>
              </div>
              <div className="builder-column with-ext">
                <input
                  type="text"
                  className="builder-input builder-input--full"
                  onChange={getInput}
                  name="billsbyURL"
                />
                <div className="input-ext">
                  <div className="builder-text">.billsby.com</div>
                </div>
              </div>
            </div>

            <div className="builder-row builder-row--two-column">
              <div className="builder-column">
                <div className="builder-input-label">Pages password</div>
              </div>
              <div className="builder-column">
                <input
                  type="password"
                  className="builder-input builder-input--full"
                  placeholder="First time here? This can be anything you like - at least 8 characters with letters, numbers and symbols"
                  onChange={getInput}
                  name="pagesPassword"
                />
              </div>
            </div>

            <div className="builder-row builder-row--two-column">
              <div className="builder-column">
                <div className="builder-input-label">API key</div>
              </div>
              <div className="builder-column">
                <input
                  type="text"
                  className="builder-input builder-input--full"
                  placeholder="We only need this if it's your first time here, or you're changing your Pages password"
                  onChange={getInput}
                  name="apiKey"
                />
              </div>
            </div>

            <button
              className="btn btn--orange builder-btn builder-btn--full"
              onClick={processLogin}
            >
              Login to Billsby Pages
            </button>

            <div className="signin-error-message">{signInError}</div>
          </div>
        </div>
      </section>

      {/* <section className="section-login-url">
        <div className="billsby-container">
          <div className="login-form-group">
            <div className="login-form-title">Billsby URL</div>
            <div className="login-form-input-group">
              <input
                type="text"
                className="login-form-input login-form-input-url"
                onChange={getInput}
                name="billsbyURL"
              />
              <span className="login-form-input-ext">.billsby.com</span>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className="section-login-password">
        <div className="billsby-container">
          <div className="login-form-group">
            <div className="login-form-title">Pages password</div>
            <div className="login-form-input-group">
              <input
                type="text"
                className="login-form-input"
                placeholder="First time here? This can be anything you like - at least 8 characters with letters, numbers and symbols"
                onChange={getInput}
                name="pagesPassword"
              />
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className="section-login-api-key">
        <div className="billsby-container">
          <div className="login-form-group">
            <div className="login-form-title">API Key</div>
            <div className="login-form-input-group">
              <input
                type="text"
                className="login-form-input"
                placeholder="We only need this if it's your first time here, or you're changing your Pages password"
                onChange={getInput}
                name="apiKey"
              />
            </div>
          </div>
        </div>
      </section> */}
    </main>
  );
};

export default Login;
