import React from "react";
import { ChromePicker } from "react-color";

class SketchExample extends React.Component {
  state = {
    displayColorPicker: false,
    color: "#000000",
  };

  constructor(props) {
    super(props);
    this.state = {
      color: props.colorScheme,
    };
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = (color) => {
    this.setState({ color: color.hex });
    this.props.onChange(color.hex);
  };

  render() {
    return (
      <div className="color-picker">
        <div
          className="color-picker__btn"
          onClick={this.handleClick}
          style={{
            backgroundColor: this.state.color,
            color: this.state.color === "#ffffff" ? "#000000" : "#ffffff",
          }}
        >
          {this.state.color}
        </div>
        {this.state.displayColorPicker ? (
          <div className="color-picker__popover">
            <div className="color-picker__cover" onClick={this.handleClose} />
            <ChromePicker
              color={this.state.color}
              onChange={this.handleChange}
              disableAlpha={true}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default SketchExample;
