import React, { useState } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
// import Select from "../shared/Select";

import Modal from "../shared/Modal";
import { useEffect } from "react";

const BillsbyProductModal = ({
  handleClose,
  show,
  data,
  handleLinkProduct,
  values,
}) => {
  const [selectedProduct, setSelectedProduct] = useState({});
  const [planList, setPlanList] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [cycleList, setCycleList] = useState([]);
  const [selectedCycle, setSelectedCycle] = useState({});
  const [step, setStep] = useState(0);
  const [defaultValues, setDefaultValues] = useState({
    product: null,
    plan: null,
    cycle: null,
  });

  var productList = [{ value: "", label: "Pick a product" }];

  data.map((product) => {
    productList.push({ value: product, label: product.displayName });
    return product;
  });

  const onChangeProduct = (product) => {
    if (product.label === "Pick a product") {
      resetStates();
    } else {
      setSelectedProduct(product.value);
      setDefaultValues((prev) => {
        prev.product = product;
        return prev;
      });
      setPlanList(
        product.value.plans.map((plan) => {
          return { value: plan, label: plan.name };
        })
      );
      setStep(1);
    }
  };

  const onChangePlan = (plan) => {
    setSelectedPlan(plan.value);
    setDefaultValues((prev) => {
      prev.plan = plan;
      return prev;
    });
    setCycleList(
      plan.value.cycles.map((cycle) => {
        return {
          value: cycle,
          label: `${
            cycle.pricingModel.priceFormatted
          }/${cycle.pricingModel.frequencyType
            .slice(0, -2)
            .toLowerCase()} (Cycle ID ${cycle.cycleId}) - ${cycle.visibility}`,
        };
      })
    );
    setStep(2);
  };

  const onChangeCycle = (cycle) => {
    setSelectedCycle(cycle.value);
    let _defaultValue = defaultValues;
    _defaultValue.cycle = cycle;
    setDefaultValues(_defaultValue);
    setStep(3);
  };

  const renderButtonText = () => {
    if (!("name" in selectedProduct) && step === 0)
      return "Show the customer all my products";
    else if ("name" in selectedProduct && step === 1) {
      return `Link to my '${selectedProduct.name}' product`;
    } else if ("name" in selectedPlan && step === 2) {
      return `Link to my '${selectedPlan.name}' plan`;
    } else if ("cycleId" in selectedCycle && step === 3) {
      return `Link to my '${
        selectedCycle.pricingModel.priceFormatted
      }/${selectedCycle.pricingModel.frequencyType
        .slice(0, -2)
        .toLowerCase()}' cycle`;
    } else {
      return "Cancel";
    }
  };

  const resetStates = () => {
    console.log("Resetting states");
    setPlanList([]);
    setCycleList([]);
    setSelectedProduct({});
    setSelectedPlan({});
    setSelectedCycle({});
    setStep(0);
    setDefaultValues({
      product: null,
      plan: null,
      cycle: null,
    });
  };

  // const handleStep = () => {
  // if (
  //   ("name" in selectedProduct && step === 0) ||
  //   ("name" in selectedPlan && step === 1) ||
  //   ("cycleId" in selectedCycle && step === 2)
  // ) {
  //   if (step < 2) {
  //     setStep(step + 1);
  //   } else {
  //     setStep(step + 1);
  //     console.log("Final step reached");
  //     // Unlink
  //     handleLinkProduct({ selectedProduct, selectedPlan, selectedCycle });
  //   }
  // } else if (!("name" in selectedProduct) && step === 0) {
  //   console.log("Unlink");
  //   // Reset all states
  //   resetStates();
  //   // Unlink
  //   handleUnlinkProduct();
  // } else {
  //   handleClose();
  // }
  // };

  const insertValues = () => {
    var _step = 0;
    const _defaultValue = {
      product: null,
      plan: null,
      cycle: null,
    };
    data.forEach((product) => {
      if (product.productId === values.product_id) {
        // Set Selected Product
        setSelectedProduct(product);
        _defaultValue.product = {
          value: product,
          label: product.name,
        };
        _step++;
        // Set Plan List
        setPlanList(
          product.plans.map((plan) => {
            if (values.plan_id !== null && values.plan_id === plan.planId) {
              // Set Selected Plan
              setSelectedPlan(plan);
              _defaultValue.plan = { value: plan, label: plan.name };
              _step++;

              // Set Cycle List
              setCycleList(
                plan.cycles.map((cycle) => {
                  let label = `${
                    cycle.pricingModel.priceFormatted
                  }/${cycle.pricingModel.frequencyType
                    .slice(0, -2)
                    .toLowerCase()} (Cycle ID ${cycle.cycleId}) - ${
                    cycle.visibility
                  }`;
                  if (
                    values.cycle_id !== null &&
                    values.cycle_id === cycle.cycleId
                  ) {
                    // Set Selected Cycle
                    setSelectedCycle(cycle);
                    _defaultValue.cycle = {
                      value: cycle,
                      label,
                    };
                    _step++;
                  }
                  return {
                    value: cycle,
                    label,
                  };
                })
              );
            }

            return { value: plan, label: plan.name };
          })
        );
      }
    });

    // Set default values for select
    setDefaultValues(_defaultValue);
    // Set Step
    setStep(_step);
  };

  useEffect(() => {
    if (values.product_id !== null && show) {
      insertValues();
    }
    //eslint-disable-next-line
  }, [values, show]);

  return (
    <Modal
      show={show}
      title="  Which product, plan or cycle should the button link to?"
      handleClose={handleClose}
    >
      <div className="modal-body">
        <Select
          options={productList}
          placeholder="Pick a product"
          onChange={onChangeProduct}
          className="filter-options"
          classNamePrefix="react-select"
          value={defaultValues.product !== null ? defaultValues.product : null}
        />
        {planList.length > 0 && step > 0 && (
          <Select
            options={planList}
            placeholder="Pick a plan"
            onChange={onChangePlan}
            className="filter-options"
            classNamePrefix="react-select"
            value={defaultValues.plan !== null ? defaultValues.plan : null}
          />
        )}
        {cycleList.length > 0 && step > 1 && (
          <Select
            options={cycleList}
            placeholder="Pick a cycle"
            onChange={onChangeCycle}
            className="filter-options"
            classNamePrefix="react-select"
            value={defaultValues.cycle !== null ? defaultValues.cycle : null}
          />
        )}
      </div>
      <div className="modal-footer">
        <button
          className="btn btn--orange "
          onClick={() => handleLinkProduct(defaultValues)}
        >
          {renderButtonText()}
        </button>
      </div>
    </Modal>
  );
};

export default BillsbyProductModal;
