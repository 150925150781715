// Dependencies
import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

const FaqItem = (props) => {
  // Props
  const { title, answer, activeTab, index, activateTab } = props;

  // State variables
  const [height, setHeight] = useState(0);

  // renderer
  const [render, setRender] = useState(0);

  // Ref
  const refPanelInner = useRef();

  // Variables
  const isActive = activeTab === index;
  const innerStyle = {
    height: `${isActive ? height : 0}px`,
  };

  useEffect(() => {
    window.setTimeout(() => {
      const height = refPanelInner.current.scrollHeight;
      // console.log(height);
      setHeight(height); // set to 60 instead of getting height; causes bug that hides the answer on load
    }, 333);
  }, []);

  useEffect(() => {
    setRender(render + 1);
    // eslint-disable-next-line
  }, [answer]);

  function createMarkup(markup) {
    return { __html: markup };
  }

  return (
    <div className="panel" role="tabpanel" aria-expanded={isActive}>
      <button className="panel__label" role="tab" onClick={activateTab}>
        <FontAwesomeIcon icon={!isActive ? faPlus : faMinus} />{" "}
        <span>{title}</span>
      </button>
      <div
        ref={refPanelInner}
        className="panel__inner"
        style={innerStyle}
        aria-hidden={!isActive}
      >
        <div
          dangerouslySetInnerHTML={createMarkup(answer)}
          className="panel__content"
        />
      </div>
    </div>
  );
};

export default FaqItem;
