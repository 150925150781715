// Dependencies
import React, { useContext, useEffect } from "react";

// Components
import SectionHeader from "../../components/builder/SectionHeader";

// Context
import { Context } from "../../context/Context";

// Dummy Data
import DataPlaceholders from "../../lib/DataPlaceholders";

const FooterContent = () => {
  const {
    pageData,
    setPageData,
    loadPageData,
    saveFooterContent,
    isLoadedPageContent,
  } = useContext(Context);

  const handleOnChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;

    let footer_content = pageData.footer_content;

    setPageData({
      ...pageData,
      footer_content: {
        ...footer_content,
        [name]: value,
      },
    });
  };

  useEffect(() => {
    if (!isLoadedPageContent) loadPageData();
    // eslint-disable-next-line
  }, [isLoadedPageContent]);

  return (
    <main className="footer-content-page">
      <section className="section-footer-content">
        <div className="billsby-container">
          <SectionHeader title={"Footer content"} />

          <div className="content-wrapper">
            <div className="builder-row builder-row--two-column">
              <div className="builder-column">
                <div className="builder-input-label">Facebook URL</div>
              </div>
              <div className="builder-column">
                <input
                  className="builder-input builder-input--full"
                  type="url"
                  name="fb_url"
                  onChange={handleOnChange}
                  value={
                    pageData.footer_content.fb_url === null
                      ? ""
                      : pageData.footer_content.fb_url
                  }
                  placeholder={DataPlaceholders.footer_content.fb_url}
                />
              </div>
            </div>

            <div className="builder-row builder-row--two-column">
              <div className="builder-column">
                <div className="builder-input-label">Twitter URL</div>
              </div>
              <div className="builder-column">
                <input
                  className="builder-input builder-input--full"
                  type="url"
                  name="twitter_url"
                  onChange={handleOnChange}
                  value={
                    pageData.footer_content.twitter_url === null
                      ? ""
                      : pageData.footer_content.twitter_url
                  }
                  placeholder={DataPlaceholders.footer_content.twitter_url}
                />
              </div>
            </div>

            <div className="builder-row builder-row--two-column">
              <div className="builder-column">
                <div className="builder-input-label">Instagram URL</div>
              </div>
              <div className="builder-column">
                <input
                  className="builder-input builder-input--full"
                  type="url"
                  name="instagram_url"
                  onChange={handleOnChange}
                  value={
                    pageData.footer_content.instagram_url === null
                      ? ""
                      : pageData.footer_content.instagram_url
                  }
                  placeholder={DataPlaceholders.footer_content.instagram_url}
                />
              </div>
            </div>

            <div className="builder-row builder-row--two-column">
              <div className="builder-column">
                <div className="builder-input-label">Copyright Notice</div>
              </div>
              <div className="builder-column">
                <input
                  className="builder-input builder-input--full"
                  type="text"
                  name="copyright_notice"
                  onChange={handleOnChange}
                  value={
                    pageData.footer_content.copyright_notice === null
                      ? ""
                      : pageData.footer_content.copyright_notice
                  }
                  placeholder={DataPlaceholders.footer_content.copyright_notice}
                />
              </div>
            </div>

            <div className="builder-row builder-row--two-column">
              <div className="builder-column">
                <div className="builder-input-label">Email Address</div>
              </div>
              <div className="builder-column">
                <input
                  className="builder-input builder-input--full"
                  type="text"
                  name="email_address"
                  onChange={handleOnChange}
                  value={
                    pageData.footer_content.email_address === null
                      ? ""
                      : pageData.footer_content.email_address
                  }
                  placeholder={DataPlaceholders.footer_content.email_address}
                />
              </div>
            </div>

            <button
              className="btn btn--orange builder-btn builder-btn--full"
              onClick={saveFooterContent}
            >
              Save footer content
            </button>
          </div>
        </div>
      </section>
    </main>
  );
};

export default FooterContent;
