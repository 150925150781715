// Dependencies
import React, { useEffect, useLayoutEffect, useState } from "react";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";

const BuilderNav = () => {
  const history = useHistory();
  const location = useLocation();
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [activeMenu, setActiveMenu] = useState("");
  const [width, setWidth] = useState(0);
  const [isCustomPage, setIsCustomPage] = useState(false);

  const customPages = [
    { path: "/main", name: "Main Page" },
    { path: "/terms", name: "Terms and Conditions" },
    { path: "/privacy", name: "Privacy Policy" },
  ];

  const currentPath = location.pathname.split("/account")[1];

  const staticLinks = [
    "/pages",
    "/domain",
    "/footer",
    "/statistics",
    "/help",
    "/delete",
    "",
  ];

  const toggleMobileMenu = () => {
    setShowMobileNav(!showMobileNav);
  };

  const getActiveMenuName = () => {
    let menuName = "";

    switch (currentPath) {
      case "/pages":
        menuName = "My Pages";
        break;

      case "/footer":
        menuName = "Footer Links";
        break;

      case "/domain":
        menuName = "Account Domain Name";
        break;

      case "/statistics":
        menuName = "Statistics";
        break;

      case "/help":
        menuName = "Help";
        break;

      case "/delete":
        menuName = "Delete Pages";
        break;

      case "/account":
        menuName = "Account Page";
        break;

      case "/pages/main":
        menuName = "Main page";
        break;

      case "/pages/terms":
        menuName = "Terms and conditions";
        break;

      case "/pages/privacy":
        menuName = "Privacy Policy";
        break;

      default:
        menuName = "";
        break;
    }

    return menuName;
  };

  const showMenuDisplay = () => {
    if (width <= 1100 && !showMobileNav) return "none";
    return "flex";
  };

  const handleOnClickLink = () => {
    setShowMobileNav(false);
    setActiveMenu(getActiveMenuName());
  };

  const checkCustomPage = () => {
    if (staticLinks.includes(currentPath)) {
      setIsCustomPage(false);
      // console.log("isStaticLink");
    } else {
      setIsCustomPage(true);
      // console.log("isCustomPage");
    }
  };

  const returnToManagePages = () => {
    history.push("/account/pages");
  };

  const getCustomPath = () => {
    //eslint-disable-next-line
    const page = customPages.find((pages) => {
      if (pages.path === currentPath.split("/pages")[1]) return pages;
    });

    if (page !== undefined) return page.name;
    return localStorage.getItem("pageName");
  };

  useLayoutEffect(() => {
    function updateWidth() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", updateWidth);
    updateWidth();
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  useEffect(() => {
    checkCustomPage();

    setActiveMenu(getActiveMenuName());
    // console.log(currentPath);

    // console.log(activeMenu);
    //eslint-disable-next-line
  }, [currentPath]);

  return (
    <section className="builder-nav">
      <div className="billsby-container">
        <div className="builder-nav__holder">
          <button
            className="btn btn--with-caret builder-nav__menu-btn"
            onClick={toggleMobileMenu}
          >
            {!isCustomPage ? activeMenu : getCustomPath()}
            <FontAwesomeIcon
              className="btn__caret"
              icon={!showMobileNav ? faAngleDown : faAngleUp}
            />
          </button>
          <div
            className=" builder-nav__mobile-content"
            style={{ display: showMenuDisplay() }}
          >
            <div className="builder-nav__title">
              <span onClick={returnToManagePages}>My Pages</span>
            </div>
            {!isCustomPage ? (
              <>
                <div className="builder-nav__first">
                  <NavLink
                    to="/account/pages"
                    className="builder-nav__item"
                    activeClassName="builder-nav__item--active"
                    exact
                    onClick={handleOnClickLink}
                  >
                    Manage Pages
                  </NavLink>
                  {/* <NavLink
                to="/account/edit"
                className="builder-nav__item"
                activeClassName="builder-nav__item--active"
                isActive={checkIfEditPage}
                exact
                onClick={handleOnClickLink}
              >
                Edit page
              </NavLink>
              <NavLink
                to="/account/terms"
                className="builder-nav__item"
                activeClassName="builder-nav__item--active"
                exact
                onClick={handleOnClickLink}
              >
                Terms and conditions
              </NavLink>
              <NavLink
                to="/account/privacy"
                className="builder-nav__item"
                activeClassName="builder-nav__item--active"
                exact
                onClick={handleOnClickLink}
              >
                Privacy policy
              </NavLink> */}
                  <NavLink
                    to="/account/domain"
                    className="builder-nav__item"
                    activeClassName="builder-nav__item--active"
                    exact
                    onClick={handleOnClickLink}
                  >
                    Account Domain Name
                  </NavLink>
                  <NavLink
                    to="/account/footer"
                    className="builder-nav__item"
                    activeClassName="builder-nav__item--active"
                    exact
                    onClick={handleOnClickLink}
                  >
                    Footer Links
                  </NavLink>

                  <NavLink
                    to="/account/statistics"
                    className="builder-nav__item"
                    activeClassName="builder-nav__item--active"
                    exact
                    onClick={handleOnClickLink}
                  >
                    Statistics
                  </NavLink>
                </div>

                <div className="builder-nav__second">
                  <NavLink
                    to="/account/help"
                    className="builder-nav__item"
                    activeClassName="builder-nav__item--active"
                    exact
                    onClick={handleOnClickLink}
                  >
                    Help
                  </NavLink>
                  <NavLink
                    to="/account/delete"
                    className="builder-nav__item"
                    activeClassName="builder-nav__item--active"
                    exact
                    onClick={handleOnClickLink}
                  >
                    Delete Pages
                  </NavLink>
                </div>
              </>
            ) : (
              <div className="builder-nav__custom-path-container">
                <div className="builder-nav__custom-path">
                  {getCustomPath()}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default BuilderNav;
