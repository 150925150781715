// Dependencies
import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";

// Components
import SectionHeader from "../../components/builder/SectionHeader";
import BuilderEditor from "../../components/builder/BuilderEditor";

// Context
import { Context } from "../../context/Context";

const TermsAndConditions = () => {
  const {
    pageData,
    setPageData,
    saveTermsAndConditions,
    isLoadedPageContent,
    loadPageData,
  } = useContext(Context);

  const handleOnChange = (value, name) => {
    setPageData({ ...pageData, terms: value });
  };

  useEffect(() => {
    if (!isLoadedPageContent) loadPageData();
    // eslint-disable-next-line
  }, [isLoadedPageContent]);

  let host = window.location.host;
  let parts = host.split(".");
  let company_name = parts[0];

  return (
    <React.Fragment>
      {isLoadedPageContent ? (
        <>
          <Helmet>
            <title>{pageData.metadata_title}</title>
            <meta name="description" content={pageData.metadata_description} />
            <script
              src="https://checkoutlib.billsby.com/checkout.min.js"
              data-billsby-company={company_name}
            ></script>
          </Helmet>
          {(() => {
            if (typeof window.scanDomBillsby !== "undefined") {
              window.scanDomBillsby();
            }

            console.log("Page Content Loaded");
          })()}
          <main className="terms-page">
            <section className="section-terms">
              <div className="billsby-container">
                <SectionHeader
                  title={"Terms and conditions"}
                  subtitle={
                    "If you leave this blank, we'll hide the terms and conditions link from your footer."
                  }
                />

                <div className="content-wrapper">
                  <BuilderEditor
                    value={pageData.terms}
                    callBack={handleOnChange}
                  />

                  <button
                    className="btn btn--orange builder-btn builder-btn--full"
                    onClick={saveTermsAndConditions}
                  >
                    Save terms and conditions
                  </button>
                </div>
              </div>
            </section>
          </main>
        </>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default TermsAndConditions;
