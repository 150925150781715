import React, { useContext, useState, useRef, useEffect } from "react";
import { Context } from "../../context/Context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
const SelectUploadButton = ({ name, page }) => {
  const {
    onImageSelect,
    logoUploadPercent,
    backgroundUploadPercent,
  } = useContext(Context);

  const [percentage, setPercentage] = useState(() => {
    if (name === "image_logo") {
      return logoUploadPercent;
    } else if (name === "image_background") {
      return backgroundUploadPercent;
    } else {
      return 0;
    }
  });
  const [uploadBtnStatus, setUploadBtnStatus] = useState("upload");

  const onImageUpload = (event) => {
    setUploadBtnStatus("uploading");
    onImageSelect(event, page)
      .then((result) => {
        // console.log(result);
        // if (result.uploaded) setUploadBtnStatus("uploaded");
        // setTimeout(() => {
        //   setUploadBtnStatus("upload");
        // }, 1000);
      })
      .catch((err) => {
        setUploadBtnStatus("Error");
      });
  };

  const buttonText = () => {
    if (uploadBtnStatus === "uploading") return "Uploading...";
    else if (uploadBtnStatus === "uploaded")
      return (
        <>
          <FontAwesomeIcon
            icon={faCheckCircle}
            style={{ marginRight: "5px" }}
          />
          Uploaded
        </>
      );
    else return "Select file and upload";
  };

  const hiddenFileInput = useRef(null);

  const handleOnClick = () => {
    if (uploadBtnStatus !== "uploading") hiddenFileInput.current.click();
  };

  useEffect(() => {
    if (name === "image_logo") {
      setPercentage(logoUploadPercent);
    } else if (name === "image_background") {
      setPercentage(backgroundUploadPercent);
    } else {
      setPercentage(0);
    }
    //eslint-disable-next-line
  }, [logoUploadPercent, backgroundUploadPercent]);

  useEffect(() => {
    if (percentage === 100) {
      setUploadBtnStatus("uploaded");
      setTimeout(() => {
        setUploadBtnStatus("upload");
      }, 5000);
    }
  }, [percentage]);

  return (
    <>
      <input
        type="file"
        onChange={onImageUpload}
        name={name}
        accept=".jpeg, .png, .jpg, .svg"
        style={{ display: "none" }}
        ref={hiddenFileInput}
      />
      <button
        className="btn btn--orange builder-btn builder-btn--full"
        onClick={handleOnClick}
        style={{ backgroundColor: percentage > 0 ? "#FFCCAB" : "#FE7F2D" }}
      >
        <span className="builder-btn__text">{buttonText()}</span>
        {percentage > 0 && (
          <div
            className="builder-btn__progress"
            style={{ width: `${percentage}%` }}
          ></div>
        )}
      </button>
    </>
  );
};

export default SelectUploadButton;
