import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../context/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
const UploadButton = ({ index, image_for, page, img, removeImage }) => {
  const {
    onImageSelect,
    galleryUploadPercent1,
    galleryUploadPercent2,
    galleryUploadPercent3,
    galleryUploadPercent4
  } = useContext(Context);
  const hiddenFileInput = React.useRef(null);
  const handleOnClick = () => {
    if (uploadBtnStatus !== 'uploading') hiddenFileInput.current.click();
  };

  const [uploadBtnStatus, setUploadBtnStatus] = useState('upload');
  const [percentage, setPercentage] = useState(() => {
    if (image_for === 'image_gallery_1') {
      return galleryUploadPercent1;
    } else if (image_for === 'image_gallery_2') {
      return galleryUploadPercent2;
    } else if (image_for === 'image_gallery_3') {
      return galleryUploadPercent3;
    } else if (image_for === 'image_gallery_4') {
      return galleryUploadPercent4;
    } else {
      return 0;
    }
  });
  const [removeLabel, setRemoveLabel] = useState('Remove');

  const [uploadedImg, setUploadedImg] = useState(null);

  const onImageUpload = (event) => {
    setUploadBtnStatus('uploading');
    onImageSelect(event, page)
      .then((result) => {
        // if (percentage === 100) setUploadBtnStatus("uploaded");
        // setTimeout(() => {
        //   setUploadBtnStatus("upload");
        // }, 3000);
      })
      .catch((err) => {
        setUploadBtnStatus('Error');
      });
  };

  const buttonText = () => {
    // if (uploadStatus.ref === `image_gallery_${index}`) {
    if (uploadBtnStatus === 'uploading') return 'Uploading...';
    else if (uploadBtnStatus === 'uploaded')
      return (
        <>
          <FontAwesomeIcon
            icon={faCheckCircle}
            style={{ marginRight: '5px' }}
          />
          Uploaded
        </>
      );
    else return 'Upload';
    // } else return "Upload";
  };

  const checkUploaded = () => {
    if (img?.uploaded) {
      setUploadedImg(img);
    } else {
      setUploadedImg(null);
    }
  };

  const handleRemove = () => {
    removeImage(img);
    setRemoveLabel('Removing...');
  };

  useEffect(() => {
    if (image_for === 'image_gallery_1') {
      setPercentage(galleryUploadPercent1);
    } else if (image_for === 'image_gallery_2') {
      setPercentage(galleryUploadPercent2);
    } else if (image_for === 'image_gallery_3') {
      setPercentage(galleryUploadPercent3);
    } else if (image_for === 'image_gallery_4') {
      setPercentage(galleryUploadPercent4);
    } else {
      setPercentage(0);
    }
    //eslint-disable-next-line
  }, [
    galleryUploadPercent1,
    galleryUploadPercent2,
    galleryUploadPercent3,
    galleryUploadPercent4
  ]);

  useEffect(() => {
    if (percentage === 100) {
      setUploadBtnStatus('uploaded');
      setTimeout(() => {
        setUploadBtnStatus('upload');
      }, 5000);
    }
    setRemoveLabel('Remove');
  }, [percentage]);

  useEffect(() => {
    checkUploaded();
    // eslint-disable-next-line
  }, [img]);

  return (
    <>
      <div className='gallery-upload__btn-holder'>
        <input
          type='file'
          onChange={onImageUpload}
          name={`image_gallery_${index}`}
          accept='.jpeg, .png, .jpg, .svg'
          style={{ display: 'none' }}
          ref={hiddenFileInput}
        />
        <button
          className='btn btn--orange builder-btn builder-btn--full'
          onClick={handleOnClick}
          style={{
            backgroundColor: percentage > 0 ? '#FFCCAB' : '#FE7F2D'
          }}
        >
          <span className='builder-btn__text'>{buttonText()}</span>
          {percentage > 0 && (
            <div
              className='builder-btn__progress'
              style={{ width: `${percentage}%` }}
            ></div>
          )}
        </button>
        {uploadedImg && (
          <button
            onClick={handleRemove}
            className='btn btn--light builder-btn builder-btn--full remove-btn'
          >
            {removeLabel}
          </button>
        )}
      </div>
    </>
  );
};

export default UploadButton;
