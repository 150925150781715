// Dependencies
import React from "react";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import ReactFC from "react-fusioncharts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

const BuilderChart = (props) => {
  console.log(props.data);

  const chartConfigs = {
    type: "column2d",
    width: "100%",
    height: 460,
    dataFormat: "json",
    dataSource: `{
      "chart": {
        "theme": "fusion",
        "paletteColors": "#FE7F2D",
        "bgColor": "#FE7F2D",
        "bgAlpha": "0",
        
        "baseFontSize": "17",
        "labelFontSize": "17",
        "valueFontSize": "17",

        "plotSpacePercent": "10",
        "labelStep": "3",

        "yAxisMaxValue": "",
        "yAxisMinValue": "0",

        "numVisiblePlot": "9",
        "numVisibleLabels": "8",

        "canvasbgColor": "#fff2ea",
        "canvasbgAlpha": "100",

        "divLineColor": "#243F5C",
        "divLineAlpha": "100",
        "divLineDashed": "1",
        "divLineDashLen": "1",
        "divLineDashGap": "1"
      },
      "data": ${JSON.stringify(props.data)}
    }`,
  };

  return (
    <div className="builder-chart">
      <ReactFC {...chartConfigs} />
    </div>
  );
};

export default BuilderChart;
