class Auth {
  constructor() {
    if (typeof window !== `undefined`) {
      const token = localStorage.getItem("billsbyPageToken");
      const email = localStorage.getItem("billsbyURL");
      const api_key = localStorage.getItem("apiKey");

      if (token && email && api_key) {
        localStorage.setItem("authenticated", true);
      }
    }
  }

  login(token, api_key, billsby_url, isBPPlus, cid, sid, plan_id, callback) {
    if (typeof window !== `undefined`) {
      localStorage.setItem("apiKey", api_key);
      // localStorage.setItem("billsbyPageUserID", user_id);
      localStorage.setItem("billsbyURL", billsby_url);
      localStorage.setItem("billsbyPageToken", token);
      localStorage.setItem("authenticated", true);
      localStorage.setItem("isBPPlus", isBPPlus ? isBPPlus : "false");
      localStorage.setItem("cid", cid);
      localStorage.setItem("sid", sid);
      localStorage.setItem("plan_id", plan_id);
    }

    callback();
  }

  getAuthConfig() {
    if (typeof window !== `undefined`) {
      if (localStorage.getItem("authenticated")) {
        return {
          headers: {
            token: `Bearer ${localStorage.getItem("billsbyPageToken")}`,
            billsby_url: localStorage.getItem("billsbyURL"),
            api_key: localStorage.getItem("apiKey"),
          },
        };
      }
    }
  }

  get(attribute) {
    if (typeof window !== `undefined`) {
      return localStorage.getItem(attribute);
    }
  }

  logout() {
    if (typeof window !== `undefined`) {
      localStorage.clear();
    }
  }

  isAuthenticated() {
    if (typeof window !== `undefined`) {
      return localStorage.getItem("authenticated");
    }
  }
}

export default new Auth();
