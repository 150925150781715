// Dependencies
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useRouteMatch } from 'react-router-dom';

import API_PATH from '../../helpers/APIPaths';
import auth from '../../helpers/Auth';
import PageDataAPISync from '../../lib/PageDataAPISync';

// Page Editor Sections
import ColorScheme from '../../components/builder/page-editor/ColorScheme';
import MetaData from '../../components/builder/page-editor/MetaData';
import HeaderPreview from '../../components/builder/page-editor/HeaderPreview';
import SalesPitch from '../../components/builder/page-editor/SalesPitch';
import Gallery from '../../components/builder/page-editor/Gallery';
import Faqs from '../../components/builder/page-editor/Faqs';

// Context
import { Context } from '../../context/Context';
import { dummyCustomPageData } from '../../lib/DummyPageData';
import { removeImgAPI } from '../../lib/APIServices';

const EditCustomPage = () => {
  const { createToast, onQueue } = useContext(Context);
  const match = useRouteMatch();
  const pathName = match.params.custom_page;

  const [customPageData, setCustomPageData] = useState(dummyCustomPageData);
  const [isLoadedPageContent, setIsLoadedPageContent] = useState(false);
  const [isUploadingImage, setIsUploadingImage] = useState(false);

  const handleSavePage = () => {
    const payload = {
      metadata_title: customPageData.metadata_title,
      metadata_description: customPageData.metadata_description,
      color_scheme: customPageData.color_scheme,
      heading_text: customPageData.heading_text,
      subscribe_button_text: customPageData.subscribe_button_text,
      manage_account_text: customPageData.manage_account_text,
      sales_pitch: customPageData.sales_pitch,
      faqs: JSON.stringify(customPageData.faqs)
    };

    axios
      .post(
        pathName === 'main'
          ? API_PATH.save_page_data
          : API_PATH.update_custompages_details + pathName,
        payload,
        auth.getAuthConfig()
      )
      .then(({ data }) => {
        console.log(data);
        createToast('success', 'Changes saved successfully', data?.preview);
      })
      .catch((err) => {
        console.error(err);
        createToast('error', 'Something went wrong');
      });
  };

  // Load Page Data
  const loadCustomPageData = () =>
    axios
      .get(
        pathName === 'main'
          ? API_PATH.fetch_page_data
          : API_PATH.get_custompages_details + pathName,
        auth.getAuthConfig()
      )
      .then(({ data }) => {
        if (data.page_data === null) {
          console.log('No Page Data Loaded');
          setCustomPageData(dummyCustomPageData);
          setIsLoadedPageContent(true);
        } else {
          const updated_data = PageDataAPISync.syncPageData(
            customPageData,
            data.page_data
          );
          setCustomPageData(updated_data);
          // Load image
          loadImages(updated_data);
        }
      })
      .catch((err) => console.error(err));

  // Load Images
  const loadImages = async (pageData) =>
    axios
      .get(API_PATH.fetch_image_all + `&path=${pathName}`, auth.getAuthConfig())
      .then(({ data }) => {
        console.log('Loaded Page Data Images');
        const updated_images = PageDataAPISync.syncImages(
          pageData ?? customPageData,
          data
        );

        setCustomPageData((oldPageData) => {
          setIsLoadedPageContent(true);
          return {
            ...oldPageData,
            logo: updated_images.__logo,
            background_image: updated_images.__background,
            gallery: updated_images.__gallery
          };
        });
      })
      .catch((err) => console.error(err));

  const removeImage = async (img) => {
    console.log('Removing', img);
    removeImgAPI({
      file_name: img.name,
      img_path: img.src,
      thumbnail_path: img.thumbnail
    })
      .then((result) => {
        setTimeout(() => {
          loadCustomPageData();
          createToast('success', 'Image removed successfully');
        }, 5000);
      })
      .catch((err) => {
        createToast('error', 'Error removing image');
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!isLoadedPageContent) {
      loadCustomPageData();
    }
    //eslint-disable-next-line
  }, [isLoadedPageContent]);

  // For re-rendering images
  useEffect(() => {
    console.log('onQueue:', onQueue);
    if (onQueue === 1 && !isUploadingImage) {
      setIsUploadingImage(true);
    }

    if (onQueue === 0 && isUploadingImage) {
      console.log('REFRESH IMAGES');
      loadImages();
      setIsUploadingImage(false);
    }
    // eslint-disable-next-line
  }, [onQueue]);

  return (
    <main className='edit-page'>
      {isLoadedPageContent ? (
        <>
          <ColorScheme
            customPageData={customPageData}
            setCustomPageData={setCustomPageData}
          />

          <MetaData
            customPageData={customPageData}
            setCustomPageData={setCustomPageData}
          />

          <HeaderPreview
            customPageData={customPageData}
            setCustomPageData={setCustomPageData}
            pathName={pathName}
          />

          <SalesPitch
            customPageData={customPageData}
            setCustomPageData={setCustomPageData}
          />

          <Gallery
            customPageData={customPageData}
            pathName={pathName}
            removeImage={removeImage}
          />

          <Faqs
            customPageData={customPageData}
            setCustomPageData={setCustomPageData}
          />

          <section className='section-save'>
            <div className='billsby-container'>
              <button
                className='btn btn--orange builder-btn builder-btn--full'
                onClick={handleSavePage}
              >
                Save changes to page
              </button>
            </div>
          </section>
        </>
      ) : (
        <div className='billsby-container'>
          <p className='builder-text'>Loading page data ...</p>
        </div>
      )}
    </main>
  );
};

export default EditCustomPage;
