// Dependencies
import React from "react";

// Components
import SectionHeader from "../../components/builder/SectionHeader";

const PageUrl = () => {
  const billsby_url = localStorage.getItem("billsbyURL");
  const url_split = billsby_url.split(".");
  const companyname = url_split[0];
  return (
    <main className="url-page">
      <section className="section-url">
        <div className="billsby-container">
          <SectionHeader title={"Page URL"} />

          <div className="content-wrapper">
            <p className="builder-text">
              Your page is hosted at{" "}
              <a
                className="builder-link"
                href={`https://${companyname}.subscription.page`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {`https://${companyname}.subscription.page`}
              </a>
            </p>
            <p className="builder-text">
              At the moment, we can't host it at your domain name. This is
              something we might offer in the future. However, your domain name
              provider should offer the option to redirect customers from your
              domain name to your Billsby Page. Here are some instructions for
              the most popular domain name registrars:
            </p>
            <div className="links-holder">
              <a
                href="https://www.ionos.co.uk/help/domains/forwarding-a-domain/forwarding-a-domain-to-a-different-domain/"
                className="builder-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                1&amp;1 (Ionos)
              </a>
              <a
                href="https://my9.bluehost.com/hosting/help/redirects"
                className="builder-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                BlueHost
              </a>
              <a
                href="https://www.domain.com/help/article/domain-management-how-to-update-domain-pointers"
                className="builder-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Domain.com
              </a>
              <a
                href="https://www.godaddy.com/help/forward-my-domain-12123"
                className="builder-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                GoDaddy
              </a>
              <a
                href="https://support.google.com/domains/answer/4522141"
                className="builder-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Google Domains
              </a>
              <a
                href="https://www.hostgator.com/help/article/url-redirect-how-to-create"
                className="builder-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                HostGator
              </a>
              <a
                href="https://www.namecheap.com/support/knowledgebase/article.aspx/385/2237/how-to-redirect-a-url-for-a-domain"
                className="builder-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Namecheap
              </a>
              <a
                href="https://www.name.com/support/articles/205188658-Adding-URL-Forwarding"
                className="builder-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Name.com
              </a>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default PageUrl;
