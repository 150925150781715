// Dependencies
import React, { useContext, useEffect } from "react";
import { Markup } from "interweave";

// Contexts
import { Context } from "../../context/Context";

// Components
import PageHeader from "../../components/billsby-page/PageHeader";
import PageFooter from "../../components/billsby-page/PageFooter";
import NoPathYet from "../../components/billsby-page/NoPathYet";

const PagePrivacy = () => {
  const { pageData, loadMainPage, isLoadedPageContent } = useContext(Context);
  let host = window.location.host;
  let parts = host.split(".");
  let company_name = parts[0];

  useEffect(() => {
    loadMainPage(company_name);
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      {isLoadedPageContent && (
        <>
          {(() => {
            if (typeof window.scanDomBillsby !== "undefined") {
              window.scanDomBillsby();
            }
          })()}
          {pageData.privacy ? (
            <div className="pages-app-container">
              <PageHeader data={pageData} />
              <main className="page-privacy">
                <section className="section-privacy page-container">
                  <div className="section-privacy__title">Privacy policy</div>
                  <div className="section-privacy__content">
                    <Markup content={pageData.privacy} />
                  </div>
                </section>
              </main>
              <PageFooter name={company_name} />
            </div>
          ) : (
            <NoPathYet />
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default PagePrivacy;
