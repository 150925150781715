// Depedencies
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

// Helpers
import auth from "./Auth";

// Components
import Header from "../components/builder/Header";
import Footer from "../components/builder/Footer";
import BuilderNav from "../components/builder/BuilderNav";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <React.Fragment>
      <Helmet>
        <title>
          Account | Billsby Pages | Simple landing pages for your subscription
          business
        </title>
        <meta name="description" content="Manage your Billsby Pages account" />
      </Helmet>
      <Header />
      <BuilderNav />
      <Route
        {...rest}
        render={(props) => {
          if (auth.isAuthenticated()) {
            return <Component {...props} />;
          } else {
            return (
              <Redirect
                to={{
                  pathname: "/login",
                  state: {
                    from: props.location,
                  },
                }}
              />
            );
          }
        }}
      />
      <Footer />
    </React.Fragment>
  );
};

export default ProtectedRoute;
