// Dependencies
import React from "react"
import classNames from 'classnames'

const BuilderTab = (props) => {
  const { label, onClick, activeTab } = props

  const handleOnClick = () => {
    onClick(label);
  }

  return (
    <button
      className={classNames({
        'builder-tabs__item': true,
        'builder-tabs__item--active': activeTab === label
      })}
      onClick={handleOnClick}
    >
      {label.split(".").shift()}
    </button>
  );
};

export default BuilderTab
