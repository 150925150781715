const checkPasswordComplexity = (password) => {
  let toReturn = {
    status: false,
    error: "",
  };
  if (password.length >= 8) {
    if (password.includes(" ")) {
      toReturn.error =
        "Your password should not contain any whitespace characters";
    } else {
      // Accept atleast 1 letter, 1 character, 1 special char, and atleast 8 length
      //eslint-disable-next-line
      let validCharacters = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/@])[A-Za-z\d-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/@]{8,}$/;
      const isProperFormat = validCharacters.test(password);
      console.log("combination check:", isProperFormat);

      if (isProperFormat) {
        toReturn.status = true;
      } else {
        //eslint-disable-next-line
        let validSymbols = /[^A-Za-z\d-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/@]/g;

        let invalidSymbols =
          password.match(validSymbols) !== null
            ? password.match(validSymbols).length
            : [];
        if (invalidSymbols > 0)
          toReturn.error =
            "Sorry, one of the special characters you used in your password isn't supported. Try a different one like @ or !";
        else
          toReturn.error =
            "Your password should atleast have a combination of 1 letters, numbers and symbols (e.g. @$!%*#?&)";
      }
    }
  } else {
    toReturn.error =
      "Your password should atleast have 8 characters with letters, numbers and symbols (e.g. @$!%*#?&)";
  }

  console.log(toReturn);

  return toReturn;
};

module.exports = { checkPasswordComplexity };
